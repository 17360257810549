// src/components/Login.js
import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import InputLabel  from "../components/InputLabel";

import Modal from "./ui/Modal";
import ErrorBox from "./ui/ErrorBox";
import classes from "./Login.module.css";
import GradientButton from "./ui/GradientButton";

import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import ErrorMessage from "./ui/ErrorMessage";
import InformationBox from "./ui/InformationBox";
import useLanguage from "../hooks/useLanguage";

const Login = ({ setIsAuthenticated }) => {
  const { t } = useTranslation("general");
  const { language } = useLanguage();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgetPassword, setForgetPassword] = useState(false);
  const [pageTwo, setPageTwo] = useState({ active: false });
  const [error, setError] = useState("");

  const { loginUsing } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      // Check if the user is already authenticated
      const storedToken = localStorage.getItem("token");
      const storedUserId = localStorage.getItem("userId");

      if (storedToken && storedUserId) {
        setIsAuthenticated(true);
        navigate(`/profile/${storedUserId}`);
      }
    };

    checkAuthAndRedirect();
  }, [navigate]);

  const handleLogin = async () => {
    if (!email || !password) {
      setError("pleaseFillIn");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/login`, {
        // const response = await axios.post('https://safratake-backend.netlify.app/.netlify/functions/login', {
        email,
        password,
      });

      const {
        userId,
        token,
        username,
        email: userMail,
        userType,
        logo,
      } = response.data;

      // Store the token in localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("userId", userId);
      localStorage.setItem("username", username);
      localStorage.setItem("email", userMail);
      localStorage.setItem("userType", userType);
      localStorage.setItem("logo", logo || "");
      localStorage.setItem("isAuthenticated", true);
      // console.log("Login successful", response.data);
      // setIsAuthenticated(true);

      console.log("isAuthenticated: ", localStorage.getItem("isAuthenticated"))
      loginUsing({
        token: token,
        userId: userId,
        username: username,
        userType: userType,
        email: userMail,
        logo: logo,
      });

      // console.log("User ID:", userId);
      // console.log("User Token:", token);
      // console.log("User Email:", userMail);
      // console.log("User Type:", userType);

      navigate("/feed");
    } catch (returnedError) {
      console.error("Login failed", returnedError);
      setError(returnedError?.response?.data?.error || "networkError");
    }
  };

  const handleForgetPassword = async () => {
    console.log("Data sent");

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/forgetPassword`,
        {
          email,
          language,
        }
      );

      if (res?.data) {
        setError("");
        setPageTwo((prev) => ({ ...prev, active: true }));
      } else {
        setError("networkError");
      }

      console.log("res", res);
    } catch (err) {
      setError(err?.response?.data?.message || "networkError");
    }
  };

  return (
    <div className="body-content">
      <Container maxWidth="xs">
        <div>
          <Typography variant="h4" align="center" gutterBottom>
            {t("login")}
          </Typography>
          {error && <ErrorBox>{t(error)}</ErrorBox>}
          <form className={classes.loginForm}>
            <InputLabel title={t("email")} />
            <TextField
              // placeholder={t("email")}
              type="email"
              error={error}
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
            />

            <InputLabel title={t("password")} />
            <TextField
              // placeholder={t("password")}
              type="password"
              error={error}
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError("");
              }}
            />
            <button
              className={classes.textButton}
              type="button"
              onClick={() => setForgetPassword(true)}
            >
              {t("forgetPassword")}
            </button>
            {error && (
              <div className={classes.centeredError}>
                <ErrorMessage>{t(error)}</ErrorMessage>
              </div>
            )}

            <Button variant="contained" fullWidth onClick={handleLogin}>
              {t("login")}
            </Button>

            {forgetPassword && (
              <Modal
                pageTwo={pageTwo}
                setPageTwo={setPageTwo}
                className={classes.modal}
                noBack={true}
                toggleModal={() => {
                  setError("");
                  setPageTwo((prev) => ({ ...prev, active: false }));
                  return setForgetPassword(false);
                }}
              >
                {pageTwo?.active ? (
                  <>
                    <h2 className={classes.modalTitle}>{t("done")}</h2>
                    <InformationBox>
                      {t("resetPasswordLinkSent")}
                    </InformationBox>
                  </>
                ) : (
                  <>
                    <h2 className={classes.modalTitle}>{t("forgetPassword")}</h2>
                    {error && <ErrorBox>{t(error)}</ErrorBox>}
                    <h4 className={classes.modalSubTitle}>
                      {t("enterYourEmail")}
                    </h4>
                    <div className={classes.inputContainer}>
                      <label className={classes.inputLabel} htmlFor="email">
                        {t("email")}:
                      </label>
                      {error !== "networkError" && (
                        <ErrorMessage>{t(error)}</ErrorMessage>
                      )}

                      <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {error && (
                      <div className={classes.centeredError}>
                        <ErrorMessage>{t(error)}</ErrorMessage>
                      </div>
                    )}
                    <GradientButton handleClick={handleForgetPassword}>
                      {t("send")}
                    </GradientButton>
                  </>
                )}
              </Modal>
            )}

            {/* {error && <ErrorBox>{error}</ErrorBox>}
            <Button variant="contained" fullWidth onClick={handleLogin}>
              {t("login")}
            </Button> */}
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Login;
