import React from "react";
import Modal from "@mui/material/Modal";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const CompanyLocation = ({ open, handleClose, location }) => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: location.lat,
    lng: location.lng,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          margin: "100px auto",
          width: "80%",
          borderRadius: "8px",
        }}
      >
        <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={15}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </div>
    </Modal>
  );
};

export default CompanyLocation;
