// src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom";

import ScrollWrapper from "./components/utils/ScrollWrapper";

import Login from "./components/Login";
// import Register from "./Pages/Register/CustomerRegister";
import Register from "./components/Register";
import AgencyRegister from "./Pages/Register/AgencyRegister";
import Dashboard from "./Pages/Dashboard/Dashboard";
// import Navigation from "./components/Ar/Navigation"; // * Arabic
import Navigation from "./components/Navigation";
import Safra from "./components/Safra";
import Logout from "./components/Logout";
// import Feed from "./components/Ar/Feed"; // * Arabic
import Feed from "./components/Feed";
// import Feed from "./components/FeedOld";
import { ProvideAuth } from "./hooks/useAuth";
import Location from "./components/Location";
import Footer from "./components/Footer";
import ViewTrip from "./Pages/Trips/ViewTrip";
// import ViewTrip from "./Pages/Trips/ViewTripOld";
import BookTrip from "./Pages/Trips/BookTrip";
import CompaniesList from "./Pages/RegisterCompany/CompaniesList";
import CompanyDetails from "./Pages/RegisterCompany/CompanyDetails";
import Profile from "./Pages/Profile/Profile";
// import Profile from "./Pages/Profile/StaticProfile";
import ViewCompanyProfile from "./Pages/ViewCompanyProfile/ViewCompanyProfile";
import Developer from "./Pages/Developer/Developer";
import ViewCustomerProfile from "./Pages/ViewCustomerProfile/ViewCustomerProfile";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import CreateUser from "./Pages/AdminPanel/CreateUser";
import ExploreTrips from "./Pages/Trips/ExploreTrips";
import ViewFlights from "./Pages/flights/ViewFlights";
import CreateAboutUs from "./Pages/AdminPanel/CreateAboutUs";
import ViewAboutUs from "./Pages/Documents/ViewAboutUs";
import CreateAboutUsAr from "./Pages/AdminPanel/CreateAboutUsAr";
import CreateTermsNConditions from "./Pages/AdminPanel/CreateTermsNConditions";
import CreateTermsNConditionsAr from "./Pages/AdminPanel/CreateTermsNConditionsAr";
import CreatePrivacyPolicy from "./Pages/AdminPanel/CreatePrivacyPolicy";
import CreatePrivacyPolicyAr from "./Pages/AdminPanel/CreatePrivacyPolicyAr";
import CreateSoftwareLicenses from "./Pages/AdminPanel/CreateSoftwareLicenses";
import CreateSoftwareLicensesAr from "./Pages/AdminPanel/CreateSoftwareLicensesAr";
import ViewTermsNConditions from "./Pages/Documents/ViewTermsNConditions";
import ViewPrivacyPolicy from "./Pages/Documents/ViewPrivacyPolicy";
import ViewSoftwareLicenses from "./Pages/Documents/ViewSoftwareLicenses";
import VerfiyEmail from "./Pages/Register/VerifyEmail";
import PageNotFound from "./Pages/PageNotFound";
import SuccessPage from "./Pages/SuccessPage";
import TripIsBookedSuccessfulyPage from "./Pages/TripIsBookedSuccessfulyPage";
import FailedToBookTripPage from "./Pages/FailedToBookTripPage";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const userAuthenticated = localStorage.getItem("isAuthenticated");  
  
  function ProtectedResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const id = searchParams.get("id");

    if (token && id) {
      return <ResetPassword />;
    } else {
      return <Navigate to="/page-not-found" />;
    }
  }

  return (
    <Router>
      <ProvideAuth>
        <Navigation setIsAuthenticated={setIsAuthenticated} />

        <ScrollWrapper>
          <Routes>
            <Route
              path="/login"
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/agency" element={<AgencyRegister />} />
            <Route
              path="/dashboard"
              element={
                userAuthenticated ? <Dashboard /> : <Navigate to="/login" />
              }
            />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/" element={<Feed />} exact />
            <Route path="/aboutUs" element={<ViewAboutUs />} />
            <Route
              path="/termsNConditions"
              element={<ViewTermsNConditions />}
            />
            <Route path="/privacyPolicy" element={<ViewPrivacyPolicy />} />
            <Route
              path="/softwareLicenses"
              element={<ViewSoftwareLicenses />}
            />
            <Route path="/feed" element={<Feed />} />
            <Route path="/safra-page" element={<Safra />} />
            <Route path="/adminPanel/aboutUs" element={<CreateAboutUs />} />
            <Route path="/adminPanel/aboutUsAr" element={<CreateAboutUsAr />} />
            <Route
              path="/adminPanel/termsNConditions"
              element={<CreateTermsNConditions />}
            />
            <Route
              path="/adminPanel/termsNConditionsAr"
              element={<CreateTermsNConditionsAr />}
            />
            <Route
              path="/adminPanel/privacyPolicy"
              element={<CreatePrivacyPolicy />}
            />
            <Route
              path="/adminPanel/privacyPolicyAr"
              element={<CreatePrivacyPolicyAr />}
            />
            <Route
              path="/adminPanel/softwareLicenses"
              element={<CreateSoftwareLicenses />}
            />
            <Route
              path="/adminPanel/softwareLicensesAr"
              element={<CreateSoftwareLicensesAr />}
            />
            <Route path="/adminPanel/createUser" element={<CreateUser />} />
            <Route path="/adminPanel" element={<AdminPanel />} />
            <Route
              path="/logout"
              element={<Logout setIsAuthenticated={setIsAuthenticated} />}
            />
            {/* <Route
              path="/company/:companyId/trip/:tripId/book"
              element={<BookTrip />}
            /> */}
            <Route path="/trip/:id" element={<ViewTrip />} />
            <Route path="/registeredCompanies" element={<CompaniesList />} />
            <Route path="/company/:id" element={<ViewCompanyProfile />} />
            <Route path="/customer/:id" element={<ViewCustomerProfile />} />
            <Route
              path="/forget-password"
              element={<ProtectedResetPassword />}
            />
            <Route path="/developer" element={<Developer />} />
            <Route path="/exploreTrips" element={<ExploreTrips />} />
            <Route path="/viewFlights" element={<ViewFlights />} />
            <Route path="/verify-email" element={<VerfiyEmail />} />
            <Route path="/successPage" element={<SuccessPage />} />
            <Route path="/tripIsBookedSuccessfulyPage" element={<TripIsBookedSuccessfulyPage />} />
            <Route path="/failedToBookTripPage" element={<FailedToBookTripPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ScrollWrapper>

        <Footer />
      </ProvideAuth>
    </Router>
  );
}

export default App;
