// src/components/Navigation.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  IconButton,
  CssBaseline,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../hooks/useAuth";
import useLanguage from "../hooks/useLanguage";
import LanguageSwitcher from "./LangageSwitcher";
import { useTranslation } from "react-i18next";

import classes from "./Navigation.module.css";
import websiteLogo from "../assets/logo_2.png";

const Navigation = ({ setIsAuthenticated }) => {
  const { t } = useTranslation("general");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, admins, roles } = useAuth();
  const { language } = useLanguage();

  useEffect(() => {
    if (user) {
      setIsAuthenticated(true);
    }
  }, []);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <section className={language === "ar" ? classes.right : classes.left}>
      <CssBaseline />
      <AppBar position="sticky" sx={{ top: 0, backgroundColor: "#210340" }}>
        <Container sx={{ backgroundColor: "#210340" }}>
          <Toolbar sx={{ backgroundColor: "#210340" }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => toggleDrawer(true)}
              sx={language === "ar" ? { ml: 2 } : { mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.websiteLogo}>
              <img src={websiteLogo} alt="Safratake" />
            </div>
            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {t("websiteName")}
            </Typography> */}
            <LanguageSwitcher />
            {admins.includes(user?.userType) ? (
              <Button color="inherit" component={Link} to="/dashboard">
                {t("dashboard")}
              </Button>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>

      <div className="divider"></div>

      <Drawer
        anchor={language === "ar" ? "right" : "left"}
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        className={language === "ar" ? classes.right : classes.left}
      >
        <List sx={{ width: "200px" }}>
          {!user ? (
            <>
              <ListItem button onClick={() => toggleDrawer(false)}>
                <Button color="inherit" component={Link} to="/login">
                  {t("login")}
                </Button>
              </ListItem>

              <ListItem button onClick={() => toggleDrawer(false)}>
                <Button color="inherit" component={Link} to="/register">
                  {t("register")}
                </Button>
              </ListItem>
            </>
          ) : null}

          <ListItem button onClick={() => toggleDrawer(false)}>
            <Button color="inherit" component={Link} to="/feed">
              {t("feed")}
            </Button>
          </ListItem>
          {roles.includes(user?.userType) ? (
            <ListItem button onClick={() => toggleDrawer(false)}>
              <Button color="inherit" component={Link} to="/safra-page">
                {t("createTrip")}
              </Button>
            </ListItem>
          ) : null}

          {admins.includes(user?.userType) ? (
            <ListItem button onClick={() => toggleDrawer(false)}>
              <Button color="inherit" component={Link} to="/adminPanel">
                {t("adminPanel")}
              </Button>
            </ListItem>
          ) : null}

          {user ? (
            <>
              <ListItem button onClick={() => toggleDrawer(false)}>
                <Button
                  color="inherit"
                  component={Link}
                  to={`/profile/${user?.userId}`}
                >
                  {t("profile")}
                </Button>
              </ListItem>
            </>
          ) : null}
        </List>
      </Drawer>
    </section>
  );
};

export default Navigation;
