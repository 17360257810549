import classes from "./GradientButton.module.css";
export default function GradientButton({
  full,
  children,
  handleClick,
  disabled,
  element = "button",
  ...props
}) {
  const Element = element || "button";

  return (
    <div className={full ? classes.fullWidth : classes.fitContent}>
      <Element
        className={classes.gradientButton}
        onClick={handleClick}
        type="button"
        disabled={disabled}
        {...props}
      >
        {children}
      </Element>
    </div>
  );
}
