import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

import { useTranslation } from "react-i18next";

import { Container } from "@mui/system";

import { ProfileCover } from "../../assets";

import axios from "axios";
import classes from "./ViewCompanyProfile.module.css";
import ConfirmationBox from "../../components/ui/ConfirmationBox";
import { Menu, MenuItem } from "@mui/material";
import ErrorBox from "../../components/ui/ErrorBox";
import ErrorMessage from "../../components/ui/ErrorMessage";
import Modal from "../../components/ui/Modal";
import Card from "../../components/ui/Card";
import ViewRate from "../../components/rating/ViewRate";
import PostRate from "../../components/rating/PostRate";
import Image from "../../components/ui/Image";
import PDFViewer from "../../components/ui/PDFViewer";
import CompanyLocation from "../../components/ui/CompanyLocation";


const ViewCompanyProfile = () => {
  const { t } = useTranslation("general");
  
  const [companyData, setCompanyData] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    website: "",
    logo: "",
    day: "",
    month: "",
    year: "",
    country: "",
    city: "",
    address: "",
  });

  const [viewDocs, setViewDocs] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const { user, admins } = useAuth();

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    modalPage: 0, 
    confirmDelete: false,
  });

  const [passwordToDeleteAccount, setPasswordToDeleteAccount] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);

  const [contextMenu, setContextMenu] = useState(null);
  const [mapModal, setMapModal] = useState(false);

  const [companyTrips, setCompanyTrips] = useState([]);

  const handleApprove = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_URL}/company/${id}`, {
        action: "approve",
      });
      setCompanyData((prevCompany) => ({
        ...prevCompany,
        approved: true,
      }));
    } catch (error) {
      console.error("Failed to approve the company", error);
    }
  };

  const handleDeleteModal = () => {
    setDeleteModal((prev) => ({ ...prev, show: !prev.show }));
  };

  const handleDeleteCompany = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_URL}/company/${id}/${user?.userId}/${passwordToDeleteAccount}/${user?.userType}`
      );
      setDeleteModal((prev) => ({ ...prev, modalPage: 1 })); // Go to success page after deletion
    } catch (error) {
      console.error("Failed to reject the company request", error);
      setWrongPassword(true);
    }
  };

  const handleCloseDeleteCompanyModal = () => {
    setDeleteModal({
      show: false,
      modalPage: 0, // Reset the modal page on close
      confirmDelete: false,
    });
    navigate("/dashboard");
  };

  const renderModalContent = () => {
    switch (deleteModal.modalPage) {
      case 0:
        return (
          <>
            <h3 className={classes.modalHeading}>
              {t("AreYouSureToDeleteCompany")}
            </h3>
            <div className={classes.inputContainer}>
              <label className={classes.inputLabel}>
                {t("TypePassword")}:
              </label>
              <input
                type="password"
                value={passwordToDeleteAccount}
                onChange={(e) => {
                  setPasswordToDeleteAccount(e.target.value);
                  setWrongPassword(false);
                }}
              />
            </div>
            {wrongPassword && (
              <ErrorMessage>{t("wrongPassword")}</ErrorMessage>
            )}
            <div className={`${classes.btnGroup}`}>
              <button
                className={`btn ${classes.redButton}`}
                onClick={handleCloseDeleteCompanyModal} // Use the handleCloseDeleteCompanyModal function
              >
                {t("cancel")}
              </button>
              <button
                className={`btn ${classes.deleteAccountButton}`}
                onClick={handleDeleteCompany} // Use the delete function
              >
                {t("delete")}
              </button>
            </div>
          </>
        );
      case 1: // Success page
        return (
          <div className={classes.deleteCompanySuccessPageModal} toggleModal={handleCloseDeleteCompanyModal}>
            <img
              className="modal-checkImg"
              src="/assets/img/trip/check.svg"
              alt="Deletion successful"
            />
            <p className="modal-successful-msg">{t("companyDeleted")}</p>
          </div>
        );
      default:
        return null; // Fallback case
    }
  };

  const handleFreeze = async () => {
    try {
      setCompanyData((prevCompany) => ({
        ...prevCompany,
        frozen: !prevCompany.frozen,
      }));

      await axios.patch(`${process.env.REACT_APP_URL}/company/${id}`, {
        action: "freeze",
        frozen: !companyData.frozen,
      });
    } catch (error) {
      console.error("Failed to Freeze the company", error);
    }
  };

  const handleHide = async () => {
    try {
      setCompanyData((prevCompany) => ({
        ...prevCompany,
        hidden: !prevCompany.hidden,
      }));

      await axios.patch(`${process.env.REACT_APP_URL}/company/${id}`, {
        action: "hide",
        hidden: !companyData.hidden,
      });
    } catch (error) {
      console.error("Failed to Hide the company", error);
    }
  };

  const handleMore = (event) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX + 5,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleShowMapModal = () => {
    setMapModal(true);
  };

  const handleCloseMapModal = () => {
    setMapModal(false);
  };

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/company`,
          {
            headers: {
              id,
            },
          }
        );
        setCompanyData(response.data || []);
      } catch (error) {
        console.error("Failed to fetch the Company", error);
      }
    };

    fetchCompany();
  }, []);

  useEffect(() => {
    const fetchCompanyTrips = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL}/company/${id}/trips`
        );
        setCompanyTrips(res.data || []);
      } catch (error) {
        console.error(`Failed to Fetch Company trips`, error);
      }
    };
    fetchCompanyTrips();
  }, []);

  const cardRef = useRef(null);

  const smoothScrolling = () => {
    cardRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className={classes.profile}>
      <Container className={classes.card}>
        <section className={classes.header}>
          <h2 className={classes.profileGreeting}>
            <span className={classes.name}>{companyData.username}</span>
            <button className={classes.link} onClick={smoothScrolling}>
            {t("availableTrips")}: {companyTrips?.length}
            </button>
          </h2>
          <img
            src={ProfileCover}
            alt="profile cover"
            className={classes.coverImage}
          />
        </section>
        <section className={classes.content}>
          {companyData.logo && (
            <Image imagePath={companyData?.logo} className={classes.avatar} />
          )}

          <ViewRate
            totalRatings={companyData?.totalRatings}
            averageRating={companyData?.averageRating}
            lastSection={true}
          />

          <div className={classes.contentRow}>
            <h3 className={classes.rowTitle}>{t("generalInfo")}</h3>

            <div className={classes.readingMode}>
              <p>
                <span className={classes.infoTitle}>{t("fullName")}:</span>
                <span>{companyData.username}</span>
              </p>
              <p>
                <span className={classes.infoTitle}>{t("email")}: </span>
                <span>{companyData.email}</span>
              </p>

              <p>
                <span className={classes.infoTitle}>{t("phoneNumber")}: </span>
                <span>{companyData.phoneNumber}</span>
              </p>
              <p>
                <span className={classes.infoTitle}>{t("addAdditionalPhone")}:</span>
                <span>{companyData.additionalPhone}</span>
              </p>
            </div>
            {admins.includes(user?.userType) && companyData?.approved && (
              <>
                <img
                  src="/assets/img/company/more-icon.svg"
                  className={classes.moreIcon}
                  alt="more"
                  onClick={handleMore}
                />
                <Menu
                  open={contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                >
                  <MenuItem
                    className={classes.menuItem}
                    onClick={handleDeleteModal}
                  >
                    {t("deleteCompany")}
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={handleFreeze}>
                    {companyData.frozen ? `${t("unfreeze")}` : `${t("freeze")}`} {t("company")}
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={handleHide}>
                    {companyData.hidden ? `${t("unhide")}` : `${t("hide")}`} {t("company")}
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>

          <div className={classes.contentRow}>
            <div className={classes.readingMode}>
              <h3 className={classes.rowTitle}>{t("address")}</h3>
              <p>
                <span className={classes.infoTitle}>{t("country")}: </span>
                <span>{companyData.country}</span>
              </p>
              <p>
                <span className={classes.infoTitle}>{t("city")}: </span>
                <span>{companyData.city}</span>
              </p>
              <p>
                <span className={classes.infoTitle}>{t("address")}: </span>
                <span>{companyData.address}</span>
              </p>
              <button
                className={classes.primaryButton}
                onClick={handleShowMapModal}
              >
                {t("showLocation")}
              </button>
            </div>
          </div>

          <div className={classes.contentRow}>
            <div className={classes.readingMode}>
              <h3 className={classes.rowTitle}>{t("website")}</h3>
              <p>
                <span className={classes.infoTitle}>{t("website")}: </span>
                <a
                  href={companyData.website}
                  target="_blank"
                  className={classes.link}
                >
                  {companyData.website}
                </a>
              </p>
            </div>
          </div>
          {user?.userType === "customer" && <PostRate />}

          {admins.includes(user?.userType) && (
            <section className={classes.contentRow}>
              <div className={classes.readingMode}>
                <label className={classes.rowTitle}>{t("companyDocuments")}:</label>
                <button
                  className={classes.primaryButton}
                  onClick={() => setViewDocs((prev) => !prev)}
                >
                  {viewDocs ? `${t("hideDocs")}` : `${t("viewDocs")}`}
                </button>
                {viewDocs &&
                  companyData.docs
                    .split(",")
                    .map((fileId, index) => (
                      <PDFViewer fileId={fileId} fileNum={index + 1} />
                    ))}
              </div>
            </section>
          )}

          {admins.includes(user?.userType) &&
            (companyData?.hidden ? (
              <div className={classes.boxHolder}>
                <ErrorBox>{t("companyIsHidden")}</ErrorBox>
              </div>
            ) : companyData.frozen ? (
              <div className={classes.boxHolder}>
                <ErrorBox>{t("companyIsFrozen")}</ErrorBox>
              </div>
            ) : companyData?.approved ? (
              <div className={classes.boxHolder}>
                <ConfirmationBox>{t("companyIsApproved")}</ConfirmationBox>
              </div>
            ) : (
              <div className={classes.btnGroup}>
                <button
                  className={classes.redButton}
                  onClick={handleDeleteModal}
                >
                  {t("reject")}
                </button>
                <button className={classes.greenButton} onClick={handleApprove}>
                  {t("approve")}
                </button>
              </div>
            ))}
        </section>
      </Container>

      {deleteModal.show && (
        <Modal toggleModal={handleCloseDeleteCompanyModal} className={classes.modal}>
          {renderModalContent()} 
        </Modal>
      )}

      {companyData?.description && (
        <Card cardTitle="Description">
          <p className="rtl-p">{companyData.description}</p>
        </Card>
      )}

      {companyTrips.length ? (
        <Card cardRef={cardRef} cardTitle={t("tripsTitle")} showMore={true}>
          {companyTrips?.map((trip) => (
            <Link
              to={`/trip/${trip.id}`}
              key={trip.id}
              className={classes.trip}
            >
              <h4 className={classes.flexbox}>
                {trip.name}
                <button>{t("show")}</button>
              </h4>
              <div className={classes.columnFlexbox}>
                <p>
                  {trip.fromLocation} ➡ {trip.destination}
                </p>
                <p>
                  {trip.dateFrom.slice(0, 10)} - {trip.dateTo.slice(0, 10)}
                </p>
              </div>
            </Link>
          ))}
        </Card>
      ) : null}

      <CompanyLocation
        open={mapModal}
        handleClose={handleCloseMapModal}
        location={{ lat: 33.28275494919664, lng: 44.407327245087984 }}
      />


    </section>
  );
};

export default ViewCompanyProfile;
