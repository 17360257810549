import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { Container } from "@mui/system";

import { ProfileCover, developerAvatar, AdminAvatar } from "../../assets";

import Modal from "../../components/ui/Modal";
import ConfirmationBox from "../../components/ui/ConfirmationBox";
import ErrorBox from "../../components/ui/ErrorBox";
import ErrorMessage from "../../components/ui/ErrorMessage";

import { useAuth } from "../../hooks/useAuth";
import { useCountries } from "../../hooks/useCountries";

import axios from "axios";
import classes from "./Profile.module.css";
import ViewRate from "../../components/rating/ViewRate";
import Card from "../../components/ui/Card";
import { Link } from "react-router-dom";

import Image from "../../components/ui/Image";
import PDFViewer from "../../components/ui/PDFViewer";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

const Profile = () => {
  const { user, loginUsing } = useAuth();
  const { t } = useTranslation("general");
  const { language } = useLanguage();
  const currentDirection = language === "ar" ? "rtl" : "ltr";
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState(false);

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    additionalPhone: "",
    website: "",
    logo: "",
    day: "",
    month: "",
    year: "",
    country: "",
    city: "",
    address: "",
  });

  const [viewDocs, setViewDocs] = useState(false);

  const [tempUserData, setTempUserData] = useState({ ...userData });

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    confirmDelete: false,
  });

  const [days, setDays] = useState(
    Array.from({ length: 30 }, (_, i) => String(i + 1).padStart(2, "0"))
  );
  const [months] = useState([
    { value: "01", name: "January" },
    { value: "02", name: "February" },
    { value: "03", name: "March" },
    { value: "04", name: "April" },
    { value: "05", name: "May" },
    { value: "06", name: "June" },
    { value: "07", name: "July" },
    { value: "08", name: "August" },
    { value: "09", name: "September" },
    { value: "10", name: "October" },
    { value: "11", name: "November" },
    { value: "12", name: "December" },
  ]);
  const [years, setYears] = useState([]);

  const [changePassword, setChangePassword] = useState(false);
  const [passwordFields, setPasswordFields] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordChanged, setPasswordChanged] = useState({
    try: false,
    success: false,
  });

  const [saveAction, setSaveAction] = useState({
    try: false,
    errorMsg: "Something went wrong",
    success: false,
  });

  const cardRef = useRef(null);

  const [passengers, setPassengers] = useState([]);
  const [trips, setTrips] = useState([]);

  // * Customer profile
  const [tripDetails, setTripDetails] = useState({
    show: false,
    data: [],
  });

  useEffect(() => {
    const fetchTrips = async () => {
      if (user.userType === "company") {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_URL}/company/${user?.userId}/trips`
          );
          setTrips(res.data || []);
        } catch (error) {
          console.error(`Failed to Fetch Company trips`, error);
        }
      } else if (user.userType === "customer") {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_URL}/passenger/${user?.userId}`
          );

          if (!res) {
            console.log("Failed to get passengers");
          }

          setPassengers(res.data || []);

          const tripIds = res.data.map((trip) => trip.tripId);

          if (!tripIds.length) {
            return;
          }

          const tripsRes = await axios.get(
            `${process.env.REACT_APP_URL}/customerTrips`,
            {
              params: {
                ids: `${tripIds}`,
              },
            }
          );
          if (!tripsRes) {
            console.log("Failed to get Trips");
          }

          setTrips(tripsRes.data || []);
        } catch (error) {
          console.error(`Failed to Fetch Customer trips`, error);
        }
      }
    };
    fetchTrips();
  }, []);

  const { countries, country, setCountry, cities, setCities, city, setCity } =
    useCountries();

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countries.find(
      (c) => c.country === selectedCountry
    );
    if (selectedCountryData) {
      setCountry(selectedCountry);

      setCities(selectedCountryData.cities);
    }
  };

  const [passwordToDeleteAccount, setPasswordToDeleteAccount] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);

  // Generate years
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = 1930; year <= currentYear; year++) {
      yearOptions.push(year);
    }
    setYears(yearOptions);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/user/${user?.userId}`,
          {
            params: { userType: user.userType },
          }
        );
        if (response.data) {
          setUserData(response.data);
          setTempUserData(response.data);
          setCountry(response.data.country);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e?.preventDefault();

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}/editUser`,
        {
          username: tempUserData.username,
          email: tempUserData.email,
          phoneNumber: tempUserData.phoneNumber,
          additionalPhone: tempUserData.additionalPhone,
          day: tempUserData.day,
          month: tempUserData.month,
          year: tempUserData.year,
          country: tempUserData.country,
          city: tempUserData.city,
          address: tempUserData.address,
          userType: user.userType,
          userId: user.userId,
        }
      );
      setUserData(tempUserData);
      setEditMode(false);
      setSaveAction({ try: true, errorMsg: "", success: true });
    } catch (error) {
      console.log(error);
      setSaveAction({
        try: true,
        errorMsg: error.response.data.error,
        success: false,
      });
    }
  };
  // DELETE
  const handleDeleteAccount = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_URL}/user/${user?.userId}?userType=${user?.userType}&userPassword=${passwordToDeleteAccount}`
      );
      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("userId");
      localStorage.removeItem("logo");
      navigate("/feed");
      loginUsing(null);
    } catch (error) {
      setWrongPassword(true);
      console.error("Failed to Delete your account", error);
    }
  };

  const handleDeleteConfirmation = () => {
    setDeleteModal((prev) => ({ ...prev, show: true }));
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const toggleChangePassword = () => {
    setChangePassword(!changePassword);
    setPasswordFields({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setEditedData({ ...editedData, [name]: value });
    setTempUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    const selectedYear = document.getElementById("year").value;
    updateDays(selectedMonth, selectedYear);
  };

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    const selectedMonth = document.getElementById("month").value;
    updateDays(selectedMonth, selectedYear);
  };

  const updateDays = (selectedMonth, selectedYear) => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const dayOptions = [];
    for (let i = 1; i <= daysInMonth; i++) {
      dayOptions.push(String(i).padStart(2, "0"));
    }
    setDays(dayOptions);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordFields({ ...passwordFields, [name]: value });
  };

  const cancelChangePassword = () => {
    toggleChangePassword();
  };

  const savePasswordChanges = async () => {
    if (passwordFields.newPassword === passwordFields.confirmPassword) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/user/${user?.userId}/changepass`,
          {
            passwords: passwordFields,
            userType: user.userType,
          }
        );
        setPasswordChanged({ try: true, success: true });
      } catch {
        console.error("Sorry, the password doesn't change");
        setPasswordChanged({ try: true, success: false });
      }

      toggleChangePassword();
    }
  };

  const handleLogout = async () => {
    try {
      // Perform any necessary cleanup on the client side (e.g., remove tokens, clear local storage)
      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("userId");
      localStorage.removeItem("logo");

      // Example: Make a request to the server to log out (optional)
      await axios.post(`${process.env.REACT_APP_URL}/logout`);
      // await axios.post('https://safratake-backend.netlify.app/.netlify/functions/logout');

      // Redirect to login page after logout
      navigate("/login");
      loginUsing(null);
      console.log("Logout successful");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const avatarClasses =
    currentDirection === "ltr" ? classes.avatar : classes.avatarRtl;

  return (
    <section className={classes.profile}>
      <Container>
        <section className={classes.header}>
          <h2 className={classes.profileGreeting}>
            <span>{t("hello")}</span>
            <span className={classes.name}>{userData.username}</span>
          </h2>
          <img
            src={ProfileCover}
            alt="profile cover"
            className={classes.coverImage}
          />
        </section>
        <section className={classes.content}>
          {user?.userType === "developer" ? (
            <img
              src={developerAvatar}
              alt="profile"
              className={avatarClasses}
            />
          ) : ["superAdmin", "admin"].includes(user?.userType) ? (
            <img src={AdminAvatar} alt="profile" className={avatarClasses} />
          ) : user?.userType === "company" ? (
            <Image imagePath={user?.logo} className={avatarClasses} />
          ) : (
            <img
              src="https://picsum.photos/200"
              alt="profile"
              className={avatarClasses}
            />
          )}
          {/* ! Disable Company edit ability now, change to !== "admin" to enable*/}
          {user?.userType === "customer" ? (
            <div
              className={
                currentDirection === "ltr"
                  ? classes.profileActions
                  : classes.profileActionsRtl
              }
            >
              {editMode ? (
                <>
                  <button
                    className={classes.redButton}
                    onClick={() => {
                      toggleEditMode();
                      setSaveAction((prev) => ({ ...prev, try: false }));
                    }}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className={classes.greenButton}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {t("save")}
                  </button>
                </>
              ) : (
                <>
                  <button onClick={toggleEditMode}>{t("editProfile")}</button>
                  <button onClick={handleLogout}>{t("logout")}</button>
                </>
              )}
            </div>
          ) : (
            <div
              className={
                currentDirection === "ltr"
                  ? classes.profileActions
                  : classes.profileActionsRtl
              }
            >
              <button onClick={handleLogout}>{t("logout")}</button>
            </div>
          )}
          {/* {updatingData.try ? (
            <h3>Data updated</h3>
          ) : (
            <h3>Failed to update data</h3>
          )} */}

          <div className={classes.contentRow}>
            {saveAction.try && !saveAction.success && (
              <ErrorBox>{saveAction.errorMsg}</ErrorBox>
            )}
            <h3>{t("generalInfo")}</h3>
            {editMode ? (
              <div className={classes.editingMode}>
                <div className={classes.inputContainer}>
                  <label className={classes.inputLabel} htmlFor="username">
                    {t("fullName")}
                  </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={tempUserData.username}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <label className={classes.inputLabel} htmlFor="email">
                    {t("email")}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={tempUserData.email}
                    onChange={handleInputChange}
                  />
                </div>
                {user?.userType !== "admin" && (
                  <div className={classes.inputContainer}>
                    <label className={classes.inputLabel} htmlFor="phoneNumber">
                      {t("phoneNumber")}
                    </label>
                    <input
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={tempUserData.phoneNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
                {user?.userType === "company" && (
                  <div className={classes.inputContainer}>
                    <label
                      className={classes.inputLabel}
                      htmlFor="additionalPhone"
                    >
                      {t("whatsAppNumber")}
                    </label>
                    <input
                      type="text"
                      id="additionalPhone"
                      name="additionalPhone"
                      value={tempUserData.additionalPhone}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {user?.userType === "customer" && (
                  <>
                    <label className={classes.inputLabel}>
                      {t("dateOfBirth")}
                    </label>
                    <div className={classes.birthContainer}>
                      <div className={classes.selectBox}>
                        <label className={classes.inputLabel} htmlFor="day">
                          {t("day")}
                        </label>
                        <select
                          id="day"
                          name="day"
                          value={tempUserData.day}
                          onChange={handleInputChange}
                        >
                          <option value="">{t("day")}</option>
                          {days.map((day, index) => (
                            <option key={index} value={day}>
                              {day}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={classes.selectBox}>
                        <label className={classes.inputLabel} htmlFor="month">
                          {t("month")}
                        </label>
                        <select
                          id="month"
                          name="month"
                          value={tempUserData.month}
                          onChange={(e) => {
                            handleMonthChange(e);
                            handleInputChange(e);
                          }}
                        >
                          <option value="">{t("month")}</option>
                          {months.map((month, index) => (
                            <option key={index} value={month.value}>
                              {month.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={classes.selectBox}>
                        <label className={classes.inputLabel} htmlFor="year">
                          {t("year")}
                        </label>
                        <select
                          id="year"
                          name="year"
                          value={tempUserData.year}
                          onChange={(e) => {
                            handleYearChange(e);
                            handleInputChange(e);
                          }}
                        >
                          <option value="">{t("year")}</option>
                          {years.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className={classes.readingMode}>
                <p>
                  <span className={classes.infoTitle}>{t("fullName")}: </span>
                  <span>{userData.username}</span>
                </p>
                <p>
                  <span className={classes.infoTitle}>{t("email")}: </span>
                  <span>{userData.email}</span>
                </p>

                {user?.userType !== "admin" && (
                  <p>
                    <span className={classes.infoTitle}>
                      {t("phoneNumber")}:
                    </span>
                    <span>{userData.phoneNumber}</span>
                  </p>
                )}
                {user?.userType === "company" && (
                  <p>
                    <span className={classes.infoTitle}>
                      {t("whatsAppNumber")}
                    </span>
                    <span>{userData.additionalPhone}</span>
                  </p>
                )}

                {user?.userType === "customer" && (
                  <>
                    <p>
                      <span className={classes.infoTitle}>{t("gender")}: </span>
                      <span>{t(userData.gender)}</span>
                    </p>
                    <p>
                      <span className={classes.infoTitle}>
                        {t("dateOfBirth")}:
                      </span>
                      {userData.day && (
                        <span>
                          {userData.day}/{userData.month}/{userData.year}
                        </span>
                      )}
                    </p>
                  </>
                )}
              </div>
            )}
          </div>

          {user?.userType !== "admin" &&
            (editMode ? (
              <div className={classes.contentRow}>
                <div className={classes.editingMode}>
                  <h3>{t("address")}</h3>
                  <div className={classes.fullSelectBox}>
                    <label className={classes.inputLabel} htmlFor="country">
                      {t("country")}
                    </label>
                    <select
                      id="country"
                      required
                      name="country"
                      value={tempUserData.country}
                      onChange={(e) => {
                        handleCountryChange(e);
                        handleInputChange(e);
                      }}
                    >
                      <option value="">{t("country")}</option>
                      {countries?.map((el) => (
                        <option key={el.iso2} value={el.country}>
                          {el.country}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.fullSelectBox}>
                    <label className={classes.inputLabel} htmlFor="city">
                      {t("city")}:
                    </label>
                    <select
                      id="city"
                      required
                      value={tempUserData.city}
                      name="city"
                      onChange={(e) => {
                        setCity(e.target.value);
                        handleInputChange(e);
                      }}
                    >
                      <option value="">{t("city")}</option>
                      {cities?.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.inputContainer}>
                    <label className={classes.inputLabel} htmlFor="address">
                      {t("address")}:
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={tempUserData.address}
                      onChange={handleInputChange}
                      placeholder={t("address")}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.contentRow}>
                <div className={classes.readingMode}>
                  <h3>{t("address")}</h3>
                  <p>
                    <span className={classes.infoTitle}>{t("country")}: </span>
                    <span>{userData.country}</span>
                  </p>
                  <p>
                    <span className={classes.infoTitle}>{t("city")}: </span>
                    <span>{userData.city}</span>
                  </p>
                  <p>
                    <span className={classes.infoTitle}>{t("address")}: </span>
                    <span>{userData.address}</span>
                  </p>
                </div>
              </div>
            ))}

          {user?.userType === "company" && (
            <>
              <div className={classes.contentRow}>
                <div className={classes.readingMode}>
                  <h3>{t("website")}:</h3>
                  <div>
                    <span className={classes.infoTitle}>{t("website")}: </span>
                    <a
                      href={userData.website}
                      target="_blank"
                      className={classes.link}
                    >
                      {userData.website}
                    </a>
                  </div>
                </div>
              </div>
              <ViewRate
                totalRatings={userData?.totalRatings}
                averageRating={userData?.averageRating}
              />
              <section className={classes.contentRow}>
                <div className={classes.readingMode}>
                  <label className={classes.inputLabel}>
                    {t("companyDocs")}:
                  </label>
                  <button
                    className={classes.primaryButton}
                    onClick={() => setViewDocs((prev) => !prev)}
                  >
                    {viewDocs ? t("hideDocs") : t("viewDocs")}
                  </button>
                  {viewDocs &&
                    userData.docs
                      .split(",")
                      .map((fileId, index) => (
                        <PDFViewer fileId={fileId} fileNum={index + 1} />
                      ))}
                </div>
              </section>
            </>
          )}
          <section className={classes.contentRow}>
            {changePassword ? (
              <div className="change-password-section">
                <div className={classes.readingMode}>
                  <h3>{t("changePassword")}</h3>
                  <div className={classes.inputContainer}>
                    <label
                      className={classes.inputLabel}
                      htmlFor="currentPassword"
                    >
                      {t("currentPassword")}
                    </label>
                    <input
                      type="password"
                      id="currentPassword"
                      name="currentPassword"
                      value={passwordFields.currentPassword}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <label className={classes.inputLabel} htmlFor="newPassword">
                      {t("newPassword")}
                    </label>
                    <input
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      value={passwordFields.newPassword}
                      onChange={handlePasswordChange}
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <label
                      className={classes.inputLabel}
                      htmlFor="confirmPassword"
                    >
                      {t("confirmNewPassword")}
                    </label>
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={passwordFields.confirmPassword}
                      onChange={handlePasswordChange}
                    />
                  </div>

                  <div className={classes.passwordActions}>
                    <button
                      className={classes.redButton}
                      onClick={cancelChangePassword}
                    >
                      {t("cancel")}
                    </button>
                    <button
                      className={classes.greenButton}
                      onClick={savePasswordChanges}
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.readingMode}>
                <h3>{t("changePassword")}</h3>
                <button
                  onClick={toggleChangePassword}
                  className={classes.primaryButton}
                >
                  {t("changePassword")}
                </button>
                {passwordChanged.try &&
                  (passwordChanged.success ? (
                    <ConfirmationBox>{t("passwordChanged")}</ConfirmationBox>
                  ) : (
                    <ErrorBox>{t("passwordNotChanged")}</ErrorBox>
                  ))}
              </div>
            )}
          </section>
          <section className={classes.contentRow}>
            <h3>{t("deleteAccount")}</h3>
            <button
              className={classes.deleteAccountButton}
              onClick={handleDeleteConfirmation}
            >
              {t("deleteAccount")}
            </button>
            {deleteModal.show && (
              <Modal toggleModal={setDeleteModal} className={classes.modal}>
                <h3 className={classes.modalHeading}>
                  {t("deleteAccountConfirmation")}
                </h3>
                <div className={classes.inputContainer}>
                  <label className={classes.inputLabel}>
                    {t("typeYourPassword")}:
                  </label>
                  <input
                    type="password"
                    value={passwordToDeleteAccount}
                    onChange={(e) => {
                      setPasswordToDeleteAccount(e.target.value);
                      setWrongPassword(false);
                    }}
                  />
                </div>
                {wrongPassword && (
                  <ErrorMessage>{t("wrongPasswordMsg")}</ErrorMessage>
                )}
                <div className={`${classes.btnGroup}`}>
                  <button
                    className={`btn ${classes.redButton}`}
                    onClick={() =>
                      setDeleteModal({
                        show: false,
                        confirmDelete: false,
                      })
                    }
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className={`btn ${classes.deleteAccountButton}`}
                    onClick={handleDeleteAccount}
                  >
                    {t("delete")}
                  </button>
                </div>
              </Modal>
            )}
          </section>
        </section>
      </Container>
      {userData?.description && (
        <Card cardTitle="Description">
          <p className="rtl-p">{userData.description}</p>
        </Card>
      )}
      {trips.length ? (
        <>
          {user?.userType === "company" ? (
            <Card cardRef={cardRef} cardTitle={t("tripsTitle")} showMore={true}>
              {trips?.map((trip) => (
                <Link
                  to={`/trip/${trip.id}`}
                  key={trip.id}
                  className={classes.trip}
                >
                  <h4 className={classes.flexbox}>
                    {trip.name}
                    <button>{t("show")}</button>
                  </h4>
                  <div className={classes.columnFlexbox}>
                    <p>
                      {trip.fromLocation} ➡ {trip.destination}
                    </p>
                    <p>
                      {/* {trip.dateFrom.slice(0, 10)} - {trip.dateTo.slice(0, 10)} */}
                    </p>
                  </div>
                </Link>
              ))}
            </Card>
          ) : (
            <Card cardRef={cardRef} cardTitle="Trips" showMore={true}>
              {trips?.map((trip, index) => (
                <div
                  to={`/trip/${trip.id}`}
                  key={trip.id + index}
                  className={classes.trip}
                  onClick={() =>
                    setTripDetails({
                      show: true,
                      data: passengers[index],
                    })
                  }
                >
                  <h4 className={classes.flexbox}>
                    {trip.name}
                    <button>{t("show")}</button>
                  </h4>
                  <div className={classes.columnFlexbox}>
                    <p>
                      {trip.fromLocation} ➡ {trip.destination}
                    </p>
                    <p>
                      {/* {trip.dateFrom.slice(0, 10)} - {trip.dateTo.slice(0, 10)} */}
                    </p>
                  </div>
                </div>
              ))}
            </Card>
          )}
        </>
      ) : null}

      {tripDetails.show && (
        <Modal
          toggleModal={() =>
            setTripDetails((prev) => ({ ...prev, show: false }))
          }
        >
          <div className="passenger-data">
            <h3 className="main-title">{t("passengerInfoTitle")}: </h3>
            <p>
              <span className="info-title">{t("passengerFullName")}: </span>
              <span>
                {tripDetails.data.firstName} {tripDetails.data.middleName}{" "}
                {tripDetails.data.lastName}
              </span>
            </p>
            <p>
              <span className="info-title">
                {t("passportNumber")}:{" "}
              </span>
              <span>{tripDetails.data.passportNumber}</span>
            </p>
            <p>
              <span className="info-title">{t("passengerEmail")}: </span>
              <span>{tripDetails.data.email}</span>
            </p>
            <p>
              <span className="info-title">{t("passengerPhoneNumber")}: </span>
              <span>{tripDetails.data.phoneNumber}</span>
            </p>
            <p>
              <span className="info-title">{t("passengerGender")}: </span>
              <span>{tripDetails.data.gender}</span>
            </p>
            <p>
              <span className="info-title">{t("passengerBirth")}: </span>
              <span>
                {tripDetails.data.day}/{tripDetails.data.month}/
                {tripDetails.data.year}
              </span>
            </p>
            <p>
              <span className="info-title">{t("nationality")}: </span>
              <span>{tripDetails.data.nationality}</span>
            </p>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default Profile;
