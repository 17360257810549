// src/components/Footer.js
import React from "react";
import "./Footer.css";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import useLanguage from "../hooks/useLanguage";

const Footer = () => {
  const { t } = useTranslation("general");
  // const { language } = useLanguage();

  // const aboutUsLink = language === "ar" ? "/aboutUsAr" : "/aboutUs";
  // console.log(aboutUsLink);

  const currentYear = new Date();

  return (
    <footer id="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center newsletter-holder">
            <div className="col-lg-6">
              <h4 className="footer-newsletter-title">
                {t("joinOurNewsletter")}
              </h4>
              <p>{t("newsletterDescription")}</p>
              <form action="" method="post">
                <input type="email" name="email" className="newsletter-input" />
                <input
                  type="submit"
                  value="Subscribe"
                  className="newsletter-buttom"
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>{t("safraTake")}</h3>
              <p>
                {t("Al-Atayfia")} <br />
                {t("Baghdad")}
                <br />
                {t("Iraq")} <br />
                <br />
                <strong>{t("phone")}:</strong> 009647736522092
                <br />
                <strong>{t("email")}:</strong> info@safratake.com
                <br />
              </p>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>{t("usefulLinks")}</h4>
              <ul>
                {/* 
          <ListItem button onClick={() => toggleDrawer(false)}>
            <Button color="inherit" component={Link} to={`/softwareLicenses`}>
              Software licenses
            </Button>
          </ListItem> */}
                <li>
                  <ChevronRight className="icon" />
                  <a href="/#">{t("home")}</a>
                </li>
                <li>
                  <ChevronRight className="icon" />
                  <Link to="aboutUs">{t("aboutUs")}</Link>
                </li>
                <li>
                  <ChevronRight className="icon" />
                  <Link to="/termsNConditions">{t("termsNConditions")}</Link>
                </li>
                <li>
                  <ChevronRight className="icon" />
                  <Link to="/privacyPolicy">{t("privacyPolicy")}</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>{t("ourServices")}</h4>
              <ul>
                <li>
                  <ChevronRight className="icon" />
                  <Link to="/">{t("TourismTrips")}</Link>
                </li>
                <li>
                  <ChevronRight className="icon" />
                  <Link to="/">{t("TherapeuticTrips")}</Link>
                </li>
                <li>
                  <ChevronRight className="icon" />
                  <Link to="/">{t("ReligiousTrips")}</Link>
                </li>
                <li>
                  <ChevronRight className="icon" />
                  <Link to="/">{t("StudyingTrips")}</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>{t("ourSocialNetworks")}</h4>
              <p>{t("bio")}</p>
              <div className="social-links mt-3">
                <a href="#" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </a>
                <a href="#" className="facebook">
                  <i className="bx bxl-facebook"></i>
                </a>
                <a href="#" className="instagram">
                  <i className="bx bxl-instagram"></i>
                </a>
                <a href="#" className="google-plus">
                  <i className="bx bxl-skype"></i>
                </a>
                <a href="#" className="linkedin">
                  <i className="bx bxl-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="copyright">
          &copy; Copyright {currentYear.getFullYear()}{"      "}
          <strong>
            <span>SafraTake </span>
          </strong>
          <strong>
            <span>  -  </span>
          </strong>
          {t("allRightsReserved")}
        </div>
        <div className="credits">
          {t("implementedBy")}{" "}
          <a style={{ color: "#00ccff" }} href="https://www.lykvyd.com/">
            LYKVYD
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
