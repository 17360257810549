import { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Modal, Box, Button, TextField, CircularProgress } from "@mui/material";
import L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import classes from "./MapModal.module.css";

// Ensure leaflet icons are correctly loaded
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const RecenterMap = ({ location }) => {
  const map = useMap();
  useEffect(() => {
    if (location) {
      map.setView(location, map.getZoom());
    }
  }, [location, map]);
  return null;
};

const MapModal = ({ open, handleClose, location, setLocation }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const provider = new OpenStreetMapProvider();

  const handleSearch = async () => {
    setLoading(true);
    const results = await provider.search({ query: searchQuery });
    setSearchResults(results);
    setLoading(false);
  };

  const handleSelectLocation = (result) => {
    setLocation([result.y, result.x]); // Assuming setLocation sets [latitude, longitude]
    setSearchQuery("");
    setSearchResults([]);
  };

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        setLocation([e.latlng.lat, e.latlng.lng]); // Assuming setLocation sets [latitude, longitude]
      },
    });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modalBox}>
        <MapContainer
          center={location || [51.505, -0.09]}
          zoom={13}
          className={classes.mapContainer}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <RecenterMap location={location} />
          <MapClickHandler />
          {location && <Marker position={location} />}
        </MapContainer>
        <Box className={classes.searchBox}>
          <TextField
            label="Search for a city"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={classes.searchField}
          />
          <Button
            onClick={handleSearch}
            variant="contained"
            disabled={loading}
            className={classes.searchButton}
          >
            Search
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.progress} />
          )}
        </Box>
        <ul className={classes.resultsList}>
          {searchResults.map((result, index) => (
            <li key={index} className={classes.resultItem}>
              <Button
                onClick={() => handleSelectLocation(result)}
                className={classes.resultButton}
              >
                {result.label}
              </Button>
            </li>
          ))}
        </ul>
        <Button
          onClick={handleClose}
          variant="contained"
          className={classes.confirmButton}
        >
          Confirm Location
        </Button>
      </Box>
    </Modal>
  );
};

export default MapModal;
