import React, { useState, useEffect } from "react";
import axios from "axios";

const Image = ({ imagePath, className, gridCol, galleryControllers }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/image/${imagePath}`,
          {
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();

    // Cleanup function to revoke object URL to avoid memory leaks
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, []);

  return (
    <div className={gridCol && gridCol}>
      {imageUrl ? (
        <>
          <img src={imageUrl} alt="Uploaded Image" className={className} />
          {galleryControllers && galleryControllers}
        </>
      ) : (
        <div className="spinner-loader-container">
          <div className="spinner-loader"></div>
        </div>
      )}
    </div>
  );
};

export default Image;
