import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import classes from "./ViewDocument.module.css";
import ourLogo from "../../assets/logo_2.png";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageNotFound from "../../Pages/PageNotFound";

export default function ViewDocument({ page, pageTitle }) {
  const { user, admins } = useAuth();
  const { t } = useTranslation("general");

  const [content, setContent] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL}/document/${page}`
        );

        if (!res) {
          setError(true);
        }

        setError(false);
        setContent(res.data.content);
      } catch (err) {
        console.error(`Failed to get ${pageTitle} page`, err);
        setError(true);
      }
    };
    fetchContent();
  }, []);

  if (error) {
    return <PageNotFound />;
  }

  return (
    <div className={!error && classes.ViewDocumentWrapper}>
      <Container>
        <div className={classes.viewPage}>
          <section className={classes.landing}>
            <img
              src={ourLogo}
              alt="SafraTake logo"
              className={classes.ourLogo}
            />
            <h2 className={classes.pageTitle}>{t(pageTitle)}</h2>
            {admins.includes(user?.userType) && (
              <Link to={`/adminPanel/${page}`} className={classes.editButton}>
                {t("edit")}
              </Link>
            )}
          </section>
          <div className={classes.pageContent}>
            {content ? (
              content?.map((section, index) => (
                <section key={index}>
                  <h3 className={classes.sectionTitle}>{section.title}</h3>
                  <p className={classes.sectionParagraph}>
                    {section.paragraph}
                  </p>
                </section>
              ))
            ) : (
              <PageNotFound />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
