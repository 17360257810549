import { useTranslation } from "react-i18next";
import classes from "./Input.module.css";
import ErrorMessage from "./ErrorMessage"

export default function Input({
  id,
  label,
  type,
  value,
  setValue,
  margin,
  required,
  responsive,
  onChange,
  error
}) {
  const { t } = useTranslation("general");

  let customLabel = label;

  if (label && required) {
    customLabel = label + "*"
  }

  return (
    <div
      className={`${classes.inputContainer} ${margin === "small"
        ? classes.smallMargin
        : margin === "medium"
          ? classes.mediumMargin
          : ""
        }  ${type === "date" && !value ? classes.returnDate : ""}`}
    >
      <label
        className={`${classes.inputLabel} ${responsive ? classes.responsive : null
          }`}
        htmlFor={id}
      >
        {customLabel}
      </label>
      <input
        className={`${type === "date" ? classes.dateInput : "notdate"} ${error ? classes.invalidValue : null}`}
        id={id && id}
        type={type && type}
        value={value}
        placeholder={customLabel}
        defaultValue={type === "date" && label}
        onChange={onChange ? onChange : (e) => setValue(e.target.value)}
        required={required}
      />
      {error && <ErrorMessage>{`${t("theField")} ${label} ${t('isRequired')}`}</ErrorMessage>}
    </div>
  );
}
