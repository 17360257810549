import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  FormControl,
  TextareaAutosize,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useCountries } from "../../hooks/useCountries";
import { useImage } from "../../hooks/useImage";
import classes from "./AgencyRegister.module.css";
import ErrorBox from "../../components/ui/ErrorBox";
import InformationBox from "../../components/ui/InformationBox";
import MapModal from "../../components/ui/MapModal";
import { useLocation } from "../../hooks/useLocation";
import useLanguage from "../../hooks/useLanguage";
import { BOX_TYPE } from "../SuccessPage";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../components/ui/ErrorMessage";

const AgencyRegister = () => {
  const { language } = useLanguage();
  const { t } = useTranslation("general");

  const [formData, setFormData] = useState({
    userType: "company",
    username: "",
    email: "",
    password: "",
    phoneNumber: "",
    description: "",
    website: "",
    location: "",
  });

  const [additionalPhone, setAdditionalPhone] = useState({
    active: false,
    value: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");

  // * Maps
  const { lat, lng } = useLocation();
  const [openMapModal, setOpenMapModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([0, 0]);
  useEffect(() => {
    setSelectedLocation([lat, lng]);
  }, [lat, lng]);

  // console.log(selectedLocation);

  const {
    countries,
    country,
    setCountry,
    cities,
    setCities,
    city,
    setCity,
    address,
    setAddress,
  } = useCountries();

  const { image, handlePrepareImage } = useImage();

  const [companyDocs, setCompanyDocs] = useState(null);
  const [error, setError] = useState("");
  const [formStatus, setFormStatus] = useState({
    isSubmit: false,
    isSucceed: false,
    msg: "",
  });

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countries.find(
      (c) => c.country === selectedCountry
    );
    if (selectedCountryData) {
      setCountry(selectedCountry);
      setCities(selectedCountryData.cities);
    }
  };

  const navigate = useNavigate();
  const [infoBox, setInfoBox] = useState({ msg: "", show: false });

  const handleRegister = async (e) => {
    e.preventDefault();
    if (
      !formData.username ||
      !formData.email ||
      !formData.password ||
      !formData.phoneNumber ||
      !country ||
      !city ||
      !address ||
      !companyDocs ||
      !selectedLocation
    ) {
      setFormStatus((prev) => ({ ...prev, isSubmit: true }));
      return;
    } else {
      setFormStatus((prev) => ({ ...prev, isSubmit: true, isSucceed: true }));
    }

    try {
      let companyData = {
        ...formData,
        additionalPhone: additionalPhone.value,
        country: country,
        city: city,
        address: address,
        location: JSON.stringify(selectedLocation),
      };

      let uploadImageResponse = null;
      if (image) {
        const companyLogo = new FormData();
        companyLogo.append("image", image.imageFile);
        companyLogo.append("imageType", image.imageType);

        uploadImageResponse = await axios.post(
          `${process.env.REACT_APP_URL}/uploadImage`,
          companyLogo
        );
      }

      const companyDocuments = new FormData();
      companyDocuments.append("pdfType", `${formData.username} documents`);
      companyDocs.forEach((document) => {
        companyDocuments.append("pdf", document);
      });

      const uploadPDFResponse = await axios.post(
        `${process.env.REACT_APP_URL}/uploadPDF`,
        companyDocuments
      );

      companyData = {
        ...companyData,
        logo: uploadImageResponse?.data?.imagePath || "",
        docs: `${uploadPDFResponse.data.pdfPaths}`,
      };

      const registerResponse = await axios.post(
        `${process.env.REACT_APP_URL}/register`,
        { ...companyData, language }
      );

      // console.log("Register successful", registerResponse.data);
      setFormStatus((prev) => ({ ...prev, isSucceed: true }));
      if (registerResponse?.data) {
        navigate("/successPage", {
          state: {
            title: "done",
            description: "emailSent",
            boxType: BOX_TYPE.INFORMATION,
          },
        });
      }
    } catch (error) {
      if (error.response) {
        setInfoBox({ msg: "You already have an account", show: true });
      } else if (error.request) {
        console.log(error.request);
        setError("Please, check your connection");
      } else {
        console.log("Error", error.message);
        setError("An error occurred");
      }
    }
  };

  const handleOpenMapModal = () => setOpenMapModal(true);
  const handleCloseMapModal = () => setOpenMapModal(false);

  return (
    <div className="body-content">
      <Container maxWidth="xs">
        <div className={classes.agencyRegister}>
          <Typography variant="h4" align="center" gutterBottom>
            {t("agencyRegister")}
          </Typography>
          {formStatus.isSubmit && !formStatus.isSucceed && (
            <ErrorBox>{error || t("pleaseFillIn")}</ErrorBox>
          )}

          <form>
            <div className={classes.entity}>
              <label>{t("companyName")}</label>
              <TextField
                required
                placeholder={t("companyName")}
                fullWidth
                error={formStatus.isSubmit && !formData.username}
                value={formData.username}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, username: e.target.value }))
                }
              />
            </div>
            <div className={classes.entity}>
              <label>{t("email")}</label>
              <TextField
                placeholder={t("email")}
                type="email"
                fullWidth
                required
                error={formStatus.isSubmit && !formData.email}
                value={formData.email}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </div>
            <div className={classes.entity}>
              <label>{t("password")}</label>
              <TextField
                placeholder={t("password")}
                type="password"
                fullWidth
                required
                error={formStatus.isSubmit && !formData.password}
                value={formData.password}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, password: e.target.value }))
                }
              />
            </div>
            <div className={classes.entity}>
              <label>{t("confirmPassword")}</label>
              <TextField
                placeholder={t("confirmPassword")}
                type="password"
                fullWidth
                required
                error={
                  (formStatus.isSubmit && !confirmPassword) ||
                  confirmPassword !== formData.password
                }
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className={classes.entity}>
              <label>{t("phoneNumber")}</label>

              <TextField
                placeholder={t("phoneNumber")}
                type="text"
                fullWidth
                required
                error={formStatus.isSubmit && !formData.phoneNumber}
                value={formData.phoneNumber}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    phoneNumber: e.target.value,
                  }));
                }}
              />
            </div>
            {!additionalPhone.active ? (
              <div className={classes.buttonContainer}>
                <button
                  className={classes.additionalPhoneButton}
                  type="button"
                  onClick={() =>
                    setAdditionalPhone((prev) => ({ ...prev, active: true }))
                  }
                >
                  {t("addAdditionalPhone")}
                </button>
              </div>
            ) : (
              <div className={classes.entity}>
                <label>{t("whatsAppNumber")}</label>

                <TextField
                  placeholder={t("whatsAppNumber")}
                  type="text"
                  fullWidth
                  value={additionalPhone.value}
                  onChange={(e) =>
                    setAdditionalPhone((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                />
              </div>
            )}

            <div className={classes.entity}>
              <label>{t("companyDescription")}</label>

              <TextareaAutosize
                minRows={3}
                placeholder={t("companyDescription")}
                size="md"
                variant="outlined"
                className={classes.textArea}
                value={formData.description}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </div>

            <div className={classes.entity}>
              <label>{t("chooseTheCountry")}</label>

              <FormControl fullWidth>
                {/* <InputLabel>{t("chooseTheCountry")}</InputLabel> */}
                <Select
                  fullWidth
                  id="country"
                  required
                  error={formStatus.isSubmit && !country}
                  name="country"
                  value={country}
                  onChange={handleCountryChange}
                >
                  <MenuItem value={t("chooseTheCountry")}>
                    {t("chooseTheCountry")}
                  </MenuItem>
                  {countries?.map((el) => (
                    <MenuItem key={el.iso2} value={el.country}>
                      {el.country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.entity}>
              <label>{t("city")}</label>

              <FormControl fullWidth>
                {/* <InputLabel>{t("city")}</InputLabel> */}
                <Select
                  fullWidth
                  error={formStatus.isSubmit && !city}
                  id="city"
                  required
                  value={city}
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                >
                  {cities?.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.entity}>
              <label>{t("address")}</label>
              <input
                type="text"
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder={t("address")}
                className={`${classes.customInput} ${
                  formStatus.isSubmit && !address && classes.invalidValue
                }`}
              />
            </div>
            <div className={classes.locationContainer}>
              <Typography
                variant="body1"
                onClick={handleOpenMapModal}
                className={classes.locationText}
              >
                {`${selectedLocation[0]
                  ?.toString()
                  .slice(0, 10)} - ${selectedLocation[1]
                  ?.toString()
                  .slice(0, 10)}`}
              </Typography>
              <Button onClick={handleOpenMapModal} variant="contained">
                {t("agencyLocation")}
              </Button>
            </div>
            <div className={classes.entity}>
              <label>{t("website")}</label>
              <input
                type="text"
                value={formData.website}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, website: e.target.value }))
                }
                placeholder={t("website")}
                className={classes.customInput}
              />
            </div>
            <div className={classes.inputContainer}>
              <input
                accept="image/*"
                type="file"
                onChange={(e) =>
                  handlePrepareImage(e, `${formData.username} company logo`)
                }
                className={classes.fileInput}
                name="image"
                id="logoInput"
              />
              <label htmlFor="logoInput">
                <p className={classes.customFileInputButton}>
                  <span>{t("companyLogo")}:</span>
                  <img src="assets/img/trip/upload.svg" />
                </p>
              </label>
            </div>
            <div
              className={`${classes.inputContainer} ${
                formStatus.isSubmit && !companyDocs && classes.invalidValue
              }`}
            >
              <input
                type="file"
                required
                onChange={(e) => setCompanyDocs(Array.from(e.target.files))}
                className={classes.fileInput}
                id="companyDocsInput"
                multiple
              />
              <label htmlFor="companyDocsInput">
                <p className={classes.customFileInputButton}>
                  <span>{t("companyDocuments")}:</span>
                  <img src="assets/img/trip/upload.svg" />
                </p>
              </label>
            </div>

            {infoBox.show && (
              <InformationBox>
                {infoBox.msg}{" "}
                <Link to="/login" className={classes.linkButton}>
                  {t("login")}
                </Link>
              </InformationBox>
            )}
            {formStatus.isSubmit && !formStatus.isSucceed && (
              <ErrorMessage>{error || t("pleaseFillIn")}</ErrorMessage>
            )}
            <button className={classes.registerButton} onClick={handleRegister}>
              {t("register")}
            </button>
          </form>
        </div>
      </Container>

      <MapModal
        open={openMapModal}
        handleClose={handleCloseMapModal}
        location={selectedLocation}
        setLocation={setSelectedLocation}
      />
    </div>
  );
};

export default AgencyRegister;
