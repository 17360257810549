import React from "react";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import classes from "./googleAuth.module.css";

// !!! IMPORTANT !!!
// this should be removed from here and placed in a .env file
const clientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  "923426267316-bof4eo8e4ssctrmb888angeao1nqvquk.apps.googleusercontent.com";

const GoogleLoginMethond = () => {
  const { t} = useTranslation("general");
  const navigate = useNavigate();
  const onSuccess = async (res) => {
    console.log("Login Success: currentUser:", res);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/register`,
        {
          userType: "customer",
          username: res.profileObj.name,
          email: res.profileObj.email,
          password: "" + res.profileObj.googleId,
          location: "",
          website: "",
          logo: "",
          companyDocs: "",
        }
      );
      console.log("Registration successful", response.data);
      navigate("/dashboard");
    } catch (error) {
      console.error("Registration failed", error);
    }
  };

  const onFailure = (res) => {
    console.log("Login failed res: ", res);
  };

  return (
    <div className="login-with-google">
      <GoogleLogin
        clientId={clientId}
        buttonText={t("loginWithGoogle")}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cocookiePolicy={"single_host_origin"}
        isSignedIn={true}
      />
    </div>
  );
};

const GoogleLogout = () => {
  const onLogoutSuccess = () => {
    console.log("Logout Success");
  };

  const onLogoutFailure = (error) => {
    console.log("Logout failed: ", error);
  };

  return (
    <div>
      <GoogleLogout
        clientId={clientId}
        buttonText="Logout"
        onLogoutSuccess={onLogoutSuccess}
        onLogoutFailure={onLogoutFailure}
      />
    </div>
  );
};
export { GoogleLoginMethond, GoogleLogout };
