// src/components/Register.js
import React, { useState, useEffect } from "react";
import { gapi } from "gapi-script";

import { useTranslation } from "react-i18next";

import {
  TextField,
  Button,
  Container,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate

// * Custom hooks
import { useCountries } from "../hooks/useCountries";
import { useImage } from "../hooks/useImage";

import classes from "./Register.module.css";
import { GoogleLoginMethond } from "./googleAuth";
import ErrorBox from "./ui/ErrorBox";
import InformationBox from "./ui/InformationBox";

// this should be removed from here and placed in a .env file
// !!! IMPORTANT !!!
const clientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  "923426267316-bof4eo8e4ssctrmb888angeao1nqvquk.apps.googleusercontent.com";

const Register = () => {
  const { t } = useTranslation("general");

  // google auth init
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const [userType, setUserType] = useState("customer");
  const [username, setUsername] = useState("");
  console.log(`username`, username);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [additionalPhone, setAdditionalPhone] = useState({
    active: false,
    value: "",
  });
  const [gender, setGender] = useState("");
  const [website, setWebsite] = useState("");
  const [logo, setLogo] = useState(null);
  const [companyDocs, setCompanyDocs] = useState(null);

  const { image, handlePrepareImage, uploadImage, uploadRes } = useImage();
  console.log(`Phone`, phone);

  const [formStatus, setFormStatus] = useState({
    isSubmit: false,
    isSucceed: false,
    msg: "",
  });

  const {
    countries,
    country,
    setCountry,
    cities,
    setCities,
    city,
    setCity,
    address,
    setAddress,
  } = useCountries();

  const navigate = useNavigate();

  const [error, setError] = useState("");

  const [infoBox, setInfoBox] = useState({ msg: "", show: false });

  const handleRegister = async () => {
    try {
      let registerData = {
        userType: userType,
        username: username,
        email: email,
        password: password,
      };

      if (userType === "company") {
        // Include Company data
        registerData = {
          ...registerData,
          phoneNumber: phone,
          additionalPhone: additionalPhone.value,
          country: country,
          city: city,
          address: address,
          website: website,
        };

        const companyLogo = new FormData();
        companyLogo.append("image", image.imageFile);
        companyLogo.append("imageType", image.imageType);

        const uploadImageResponse = await axios.post(
          `${process.env.REACT_APP_URL}/uploadImage`,
          companyLogo
        );

        const companyDocuments = new FormData();

        companyDocuments.append(
          "pdfType",
          `${registerData.username} documents`
        );

        companyDocs.forEach((document) => {
          companyDocuments.append("pdf", document);
        });

        const uploadPDFResponse = await axios.post(
          `${process.env.REACT_APP_URL}/uploadPDF`,
          companyDocuments
        );

        console.log(uploadPDFResponse.data.pdfPath);

        console.log("Upload successful", uploadImageResponse.data);
        registerData = {
          ...registerData,
          logo: uploadImageResponse.data.imagePath,
          docs: `${uploadPDFResponse.data.pdfPaths}`,
        };
      }

      if (userType === "customer") {
        registerData = { ...registerData, gender };
      }

      const registerResponse = await axios.post(
        `${process.env.REACT_APP_URL}/register`,
        registerData
      );
      console.log("Register successful", registerResponse.data);
      navigate("/login");
    } catch (error) {
      if (error.response) {
        setInfoBox({ msg: "You already have an account", show: true });
      } else if (error.request) {
        console.log(error.request);
        setError("Please, check your connection");
      } else {
        console.log("Error", error.message);
        setError("An error occurred");
      }
    }
  };

  // Function to update cities based on the selected country
  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countries.find(
      (c) => c.country === selectedCountry
    );
    if (selectedCountryData) {
      setCountry(selectedCountry);

      setCities(selectedCountryData.cities);
    }
  };

  console.log(companyDocs);

  return (
    <div className="body-content">
      <Container maxWidth="xs">
        <div>
          <Typography variant="h4" align="center" gutterBottom>
            {t("register")}
          </Typography>
          <form>
            <FormControl fullWidth margin="normal">
              {/* <InputLabel>User Type</InputLabel> */}
              <Select
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              >
                <MenuItem value="customer">{t("customer")}</MenuItem>
                <MenuItem value="company">{t("company")}</MenuItem>
                <MenuItem value="admin">{t("admin")}</MenuItem>
              </Select>
            </FormControl>
            {userType === "customer" && (
              <div className={classes.inputContainer}>
                <label>{t("gender")}</label>
                <div className={classes.radioButtons}>
                  <div className={classes.button}>
                    <input
                      type="radio"
                      required
                      name="gender"
                      id="male"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={() => setGender("Male")}
                    />
                    <label htmlFor="male">{t("male")}</label>
                  </div>
                  <div className={classes.button}>
                    <input
                      type="radio"
                      required
                      name="gender"
                      id="female"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={() => setGender("Female")}
                    />
                    <label htmlFor="female">{t("female")}</label>
                  </div>
                </div>
              </div>
            )}
            <TextField
              required
              label={
                userType === "admin"
                  ? `${t("username")}` //"Username"
                  : userType === "company"
                  ? `${t("companyName")}` //"Company name"
                  : `${t("customerName")}`
              }
              fullWidth
              error={formStatus.isSubmit && !username}
              margin="normal"
              onChange={(e) => {
                setUsername(e.target.value);
                setError("");
              }}
            />
            <TextField
              label={t("email")}
              type="email"
              fullWidth
              required
              error={error}
              margin="normal"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
            />
            <TextField
              label={t("password")}
              type="password"
              fullWidth
              required
              error={error}
              margin="normal"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError("");
              }}
            />

            {userType === "company" && (
              <>
                <TextField
                  label="Phone number"
                  type="text"
                  fullWidth
                  required
                  error={error}
                  margin="normal"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setError("");
                  }}
                />
                {!additionalPhone.active ? (
                  <div className={classes.buttonContainer}>
                    <button
                      className={classes.additionalPhoneButton}
                      type="button"
                      onClick={() =>
                        setAdditionalPhone((prev) => ({
                          ...prev,
                          active: true,
                        }))
                      }
                    >
                      {t("addAdditionalPhone")}
                    </button>
                  </div>
                ) : (
                  <TextField
                    label="Additional Phone number"
                    type="text"
                    fullWidth
                    error={error}
                    margin="normal"
                    value={additionalPhone.value}
                    onChange={(e) => {
                      setAdditionalPhone((prev) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                      setError("");
                    }}
                  />
                )}
                <FormControl fullWidth margin="normal">
                  <InputLabel>{t("country")}</InputLabel>
                  <Select
                    fullWidth
                    id="country"
                    required
                    name="country"
                    value={country}
                    onChange={handleCountryChange}
                  >
                    {countries?.map((el) => (
                      <MenuItem key={el.iso2} value={el.country}>
                        {el.country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>{t("city")}</InputLabel>
                  <Select
                    fullWidth
                    id="city"
                    required
                    value={city}
                    name="city"
                    onChange={(e) => setCity(e.target.value)}
                  >
                    {cities?.map((el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Address"
                  type="text"
                  fullWidth
                  error={error}
                  margin="normal"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <TextField
                  label="Website"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
                <div className={classes["input-container"]}>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(e) =>
                      handlePrepareImage(e, `${username} company logo`)
                    }
                    className={classes["file-input"]}
                    name="image"
                    id="logoInput"
                  />
                  <label htmlFor="logoInput">
                    <p className={classes["custom-file-input-button"]}>
                      <span>{t("companyLogo")}:</span>
                      <img src="assets/img/trip/upload.svg" />
                    </p>
                  </label>
                  {logo && (
                    <span className={classes.fileName}>{logo.name}</span>
                  )}
                </div>
                <div className={classes["input-container"]}>
                  <input
                    type="file"
                    onChange={(e) => setCompanyDocs(Array.from(e.target.files))}
                    className={classes["file-input"]}
                    id="companyDocsInput"
                    multiple
                  />
                  <label htmlFor="companyDocsInput">
                    <p className={classes["custom-file-input-button"]}>
                      <span>{t("companyDocument")}:</span>
                      <img src="assets/img/trip/upload.svg" />
                    </p>
                  </label>
                </div>
              </>
            )}
            {error && <ErrorBox>{error}</ErrorBox>}
            {infoBox.show && (
              <InformationBox>
                {infoBox.msg}{" "}
                <Link to="/login" className={classes.linkButton}>
                  {t("login")}
                </Link>
              </InformationBox>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleRegister}
            >
              {t("register")}
            </Button>
          </form>

          {userType === "customer" && (
            <div className={classes.socialLoginContainer}>
              <Typography variant="h5" align="center" gutterBottom>
                {t("or")}
              </Typography>
              <GoogleLoginMethond />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Register;
