// import { useState, useEffect } from "react";
// import axios from "axios";

// export function useLocation() {
//   const [userCity, setUserCity] = useState(null);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         async (position) => {
//           const { latitude, longitude } = position.coords;
//           try {
//             const response = await axios.get(
//               `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=pk.eyJ1IjoiYXlvdWI5OSIsImEiOiJjbHdqOTRlM24wejlpMnFxbmdieDZwOWw5In0.3o7CfSQ0tYSDz5eL4UcuMA`
//             );
//             const features = response.data.features;
//             const cityFeature = features.find((feature) =>
//               feature.place_type.includes("place")
//             );

//             if (cityFeature) {
//               setUserCity(cityFeature.text);
//             } else {
//               setUserCity("Unknown");
//             }
//           } catch (error) {
//             console.error("Error fetching user City:", error);
//           }
//         },
//         (error) => alert(error.message),
//         { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//     }
//   }, []);

//   return { userCity, setUserCity };
// }

import { useState, useEffect } from "react";

export function useLocation() {
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLat(latitude);
          setLng(longitude);
        },
        (error) => {
          console.error("Error fetching location:", error);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return { lat, lng };
}
