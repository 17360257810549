// src/components/Safra.js
import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  MenuItem,
  Select,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import axios from "axios";
import ProgramDay from "./ui/ProgramDay";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import "./Safra.css";
import ErrorBox from "./ui/ErrorBox";
import Modal from "./ui/Modal";
import ErrorMessage from "./ui/ErrorMessage";
import { useImage } from "../hooks/useImage";

// ! Fast fill
import testData from "./testTrips.json";
import { useTranslation } from "react-i18next";

const Safra = () => {
  const { t } = useTranslation("general");
  const [safraName, setSafraName] = useState("");
  const [safraType, setSafraType] = useState({
    label: t("All"),
    param: "All",
  });
  const [fromLocation, setFromLocation] = useState("");
  const [destination, setDestination] = useState("");
  const [safraDescription, setSafraDescription] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [safraPrice, setSafraPrice] = useState("");
  const [offer, setOffer] = useState("");
  const [activeCompanies, setActiveCompanies] = useState([]);
  const [assignedCompany, setAssignedCompany] = useState("");
  const [seatsCount, setSeatsCount] = useState("");

  const [ImagesFields, setImagesFields] = useState({
    count: 1,
    // imageType: `Trip ${safraName} - ${this.count}`,
    max: 10,
  });

  const [tripImages, setTripImages] = useState([]);

  const { image, handlePrepareImage } = useImage();

  const [tripId, setTripId] = useState("");

  const navigate = useNavigate();
  const { user, admins, roles } = useAuth();

  const [companyData, setCompanyData] = useState([]);

  const [safraProgramme, setSafraProgramme] = useState([
    { dayNum: 1, program: "" },
    { dayNum: 2, program: "" },
    { dayNum: 3, program: "" },
  ]);

  // const safraTypes = ["All", "Tourism", "Studying", "Therapeutic", "Religious"];
  const safraTypes = [
    {
      label: t("All"),
      param: "All",
    },
    {
      label: t("Tourism"),
      param: "Tourism",
    },
    {
      label: t("Studying"),
      param: "Studying",
    },
    {
      label: t("Therapeutic"),
      param: "Therapeutic",
    },
    {
      label: t("Religious"),
      param: "Religious",
    },
  ];

  const [submissionStatus, setSubmissionStatus] = useState({
    try: false,
    success: false,
  });

  // ! Fast Fill
  const [tripNumber, setTripNumber] = useState("");
  const [allTrips, setAllTrips] = useState([]);

  const handleRedirectUser = () => {
    navigate(`/trip/${tripId}`);
  };

  //* userType => company
  useEffect(() => {
    const getCompanyData = async () => {
      if (user?.userType === "company") {
        try {
          const res = await axios.get(`${process.env.REACT_APP_URL}/company`, {
            headers: {
              id: user.userId,
            },
          });
          setCompanyData(res.data || []);
        } catch (error) {
          console.error("Failed to get Company data", error);
        }
      }
    };
    getCompanyData();
  }, []);

  //* userType => admins
  useEffect(() => {
    const getActiveCompanies = async () => {
      if (admins.includes(user?.userType)) {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_URL}/companies/approved`
          );
          setActiveCompanies(res.data || []);
        } catch (error) {
          console.error("Failed to get Approved companies data", error);
        }
      }
    };

    getActiveCompanies();
  }, []);

  const handleAddDay = () => {
    setSafraProgramme((prev) => {
      return [...prev, { dayNum: prev.length + 1, program: "" }];
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const readyToUploadImages = new FormData();
    readyToUploadImages.append("imageType", `Trip ${safraName} images`);

    console.log(tripImages);
    console.log(Boolean(tripImages));

    // Upload multiple images
    if (tripImages.length) {
      tripImages.forEach((image) => {
        readyToUploadImages.append("images", image);
      });
    }

    try {
      // Always send formData even if there are no images
      const formData = {
        safraName,
        safraType: safraType.param,
        fromLocation,
        destination,
        safraDescription,
        dateFrom: new Date(dateFrom),
        dateTo: new Date(dateTo),
        safraPrice,
        seatsCount: parseInt(seatsCount),
        safraProgramme,
        offer,
        companyId: user?.userType === "company" ? user.userId : assignedCompany,
      };

      // If there are images, upload them and include their paths in formData
      if (tripImages.length) {
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_URL}/uploadImages`,
          readyToUploadImages
        );

        formData.tripImages = uploadResponse.data.imagePaths;
      } else {
        formData.tripImages = [];
      }

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/createTrip`,
        formData
      );

      if (response.status === 201) {
        // Reset form fields after successful submission
        setSafraName("");
        setSafraType("");
        setSafraDescription("");
        setDateFrom("");
        setDateTo("");
        setSafraPrice("");
        setSafraProgramme([
          { dayNum: 1, program: "" },
          { dayNum: 2, program: "" },
          { dayNum: 3, program: "" },
        ]);
        setOffer("");
        setSubmissionStatus({ try: true, success: true });
        setTripId(response.data.id);
      } else {
        console.error("Failed to submit Safra");
        setSubmissionStatus({ try: true, success: false });
        // Handle error scenario
      }
    } catch (error) {
      console.error("Error submitting Safra:", error);
      setSubmissionStatus({ try: true, success: false });
      // Handle error scenario
    }
  };

  // ! Fast fill
  useEffect(() => {
    setAllTrips(testData);
  }, []);
  const fastFill = async () => {
    const tripData = {
      ...allTrips[tripNumber],
      companyId: assignedCompany,
    };

    const registerResponse = await axios.post(
      `${process.env.REACT_APP_URL}/createTrip`,
      tripData
    );
  };

  console.log(allTrips[tripNumber]);
  // console.log(safraType);
  // console.log(`THE IMAGE`, image);
  // console.log(`THE IMAGES`, tripImages);

  const generateUploadInputs = () => {
    return Array.from({ length: ImagesFields.count }).map((_, index) => (
      <div className="input-container" key={index}>
        <input
          accept="image/*"
          type="file"
          onChange={(e) => {
            handlePrepareImage(e, `Trip ${safraName} - image ${index + 1}`);
            setTripImages((prev) => [...prev, e.target.files[0]]);
          }}
          className="file-input"
          name="images"
          id={`tripImage${index + 1}`}
        />
        <label htmlFor={`tripImage${index + 1}`}>
          <p className="custom-file-input-button">
            <span>
              {t("uploadImage")} {index + 1}
            </span>
            <img
              src="assets/img/trip/upload.svg"
              alt={`Upload image ${index + 1}`}
            />
          </p>
        </label>
        <span className="file-name">{tripImages[index]?.name}</span>
      </div>
    ));
  };

  return !roles.includes(user?.userType) ? (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
      {t("youAreNotAllwoed")}
      </Typography>{" "}
      <button onClick={() => navigate("/")}>{t("goHome")}</button>
    </div>
  ) : (
    <div className="body-content create-safra">
      <Container maxWidth="xs">
        <div>
          <Typography variant="h4" align="center" gutterBottom>
            {t("pageTitle")}
          </Typography>

          {submissionStatus.try && !submissionStatus.success && (
            <ErrorBox>{t("emptyFieldMsg")}</ErrorBox>
          )}

          {companyData?.hidden ? (
            <ErrorBox>{t("hiddenCompanyMsg")}</ErrorBox>
          ) : companyData?.frozen ? (
            <ErrorBox>{t("frozenCompanyMsg")}</ErrorBox>
          ) : (
            <form onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                margin="normal"
                className="input-container"
              >
                <label htmlFor="tripName">{t("tripName")}</label>
                <TextField
                  placeholder={t("tripName")}
                  id="tripName"
                  fullWidth
                  value={safraName}
                  onChange={(e) => setSafraName(e.target.value)}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="normal"
                className="input-container"
              >
                <label htmlFor="tripType">{t("tripType")}</label>
                <Select
                  className="select-box"
                  id="tripType"
                  value={safraType.param}
                  onChange={(e) => {
                    setSafraType({
                      param: e.target.value,
                      label: t(e.target.value),
                    });
                  }}
                >
                  {safraTypes.map((type) => (
                    <MenuItem key={type.param} value={type.param}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label htmlFor="fromLocation">{t("fromLocation")}</label>
                <TextField
                  placeholder={t("fromLocation")}
                  id="fromLocation"
                  fullWidth
                  value={fromLocation}
                  onChange={(e) => setFromLocation(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label htmlFor="destination">{t("destination")}</label>
                <TextField
                  placeholder={t("destination")}
                  id="destination"
                  fullWidth
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label htmlFor="safraDescription">{t("tripDescription")}</label>
                <TextareaAutosize
                  className="textarea"
                  placeholder={t("tripDescription")}
                  id="safraDescription"
                  minRows={3}
                  fullWidth
                  value={safraDescription}
                  onChange={(e) => setSafraDescription(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label htmlFor="dateFrom">{t("dateFrom")}</label>
                <TextField
                  type="date"
                  id="dateFrom"
                  fullWidth
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label htmlFor="dateTo">{t("dateFrom")}</label>
                <TextField
                  type="date"
                  id="dateTo"
                  fullWidth
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label htmlFor="safraPrice">{t("price")}</label>
                <TextField
                  placeholder={t("price")}
                  id="safraPrice"
                  fullWidth
                  value={safraPrice}
                  onChange={(e) => setSafraPrice(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label htmlFor="seatsCount">{t("seatsCount")}</label>
                <TextField
                  placeholder={t("seatsCount")}
                  id="seatsCount"
                  fullWidth
                  value={seatsCount}
                  onChange={(e) => setSeatsCount(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <label htmlFor="offer">{t("offer")}</label>
                <TextareaAutosize
                  className="textarea"
                  placeholder={t("offer")}
                  id="offer"
                  minRows={3}
                  fullWidth
                  value={offer}
                  onChange={(e) => setOffer(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <Typography variant="h6" component="h3">
                  {t("tripProgramme")}
                </Typography>
                {safraProgramme?.map((day) => (
                  <ProgramDay
                    key={day.dayNum}
                    dayNum={day.dayNum}
                    value={day.program}
                    onChange={(e) => {
                      const updatedProgramDays = [...safraProgramme];
                      updatedProgramDays[day.dayNum - 1].program =
                        e.target.value;
                      setSafraProgramme(updatedProgramDays);
                    }}
                  />
                ))}
                <button
                  className="add-day-button"
                  type="button"
                  onClick={handleAddDay}
                >
                  {t("addDay")}
                </button>
              </FormControl>

              {generateUploadInputs()}
              {ImagesFields.count < ImagesFields.max && (
                <button
                  className="add-day-button"
                  type="button"
                  onClick={() =>
                    setImagesFields((prev) => ({
                      ...prev,
                      count: prev.count < 5 ? ++prev.count : prev.count,
                    }))
                  }
                >
                  {t("addImage")}
                </button>
              )}
              {admins.includes(user?.userType) && (
                <FormControl fullWidth margin="normal">
                  <label htmlFor="safraType">{t("assignTo")}</label>
                  <Select
                    className="select-box"
                    id="safraType"
                    value={assignedCompany}
                    onChange={(e) => setAssignedCompany(e.target.value)}
                  >
                    {activeCompanies.length ? (
                      activeCompanies.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.username}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        {t("noCompanies")}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}


              {/* This is section is for fast fill */}
              {/* <div className="fastFillSection">
                <input
                  type="text"
                  value={tripNumber}
                  onChange={(e) => setTripNumber(e.target.value)}
                  placeholder="Trip number"
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={fastFill}
                  sx={{ marginTop: "10px" }}
                >
                  Fast Fill
                </Button>
                <p style={{ textAlign: "center", color: "#f00" }}>
                  Admins only
                </p>
              </div> */}

              {submissionStatus.try && !submissionStatus.success && (
                <ErrorMessage>{t("emptyFieldMsg")}</ErrorMessage>
              )}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                {t("submit")}
              </Button>
            </form>
          )}

          {submissionStatus.try && submissionStatus.success && (
            <Modal toggleModal={handleRedirectUser}>
              <img
                className="modal-checkImg"
                src="/assets/img/trip/check.svg"
                alt="Booking submitted"
              />
              <p className="modal-successful-msg">
                {t("safraSubmitted")}
              </p>
            </Modal>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Safra;
