import classes from "./ErrorBox.module.css";
import useLanguage from "../../hooks/useLanguage";

export default function ErrorBox({ children }) {
  const { pageDirection } = useLanguage();
  return (
    <div className={classes.errorBox} style={{ direction: pageDirection }}>
      <img src="/assets/img/ErrorConfirmation/error.svg" alt="Error" />
      <p className={classes.errorBoxText}>{children}</p>
    </div>
  );
}
