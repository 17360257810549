import { useState, useEffect } from "react";
import classes from "./CreateDocument.module.css";
import { Link } from "react-router-dom";

export default function CreateDocumentAr({
  pageTitle,
  content,
  setContent,
  handleSave,
}) {
  const [sectionsCount, setSectionsCount] = useState(1);

  const handleAddSection = () => {
    setSectionsCount((prev) => prev + 1);
    setContent((prev) => [...prev, { title: "", paragraph: "" }]);
  };

  useEffect(() => {
    setSectionsCount(content.length);
  }, [content]);

  return (
    <div className={classes.createPage}>
      <h2 className={classes.pageTitle}>{pageTitle}</h2>
      <div className={classes.pageContent}>
        {Array.from({ length: sectionsCount }, (_, index) => (
          <section key={index} className={classes.section}>
            <h3 className={classes.sectionDetailsTitle}>القسم {index + 1}</h3>
            <div className={classes.sectionBody}>
              <div className={classes.inputContainer}>
                <label
                  className={classes.inputLabel}
                  htmlFor={`section${index + 1}Title`}
                >
                  عنوان القسم
                </label>
                <input
                  label="Section title"
                  placeholder={`عنوان القسم ${index + 1}`}
                  id={`section${index + 1}Title`}
                  value={content[index].title}
                  onChange={(e) => {
                    const updatedContent = [...content];
                    updatedContent[index].title = e.target.value;
                    setContent(updatedContent);
                  }}
                />
              </div>
              <div className={classes.textareaContainer}>
                <label
                  className={classes.inputLabel}
                  htmlFor={`section${index + 1}Paragraph`}
                >
                  النص الكامل
                </label>
                <textarea
                  id={`section${index + 1}Paragraph`}
                  placeholder={`النص الكامل`}
                  rows="10"
                  value={content[index].paragraph}
                  onChange={(e) => {
                    const updatedContent = [...content];
                    updatedContent[index].paragraph = e.target.value;
                    setContent(updatedContent);
                  }}
                ></textarea>
              </div>
            </div>
          </section>
        ))}
        <button
          onClick={handleAddSection}
          className={`${classes.button} ${classes.greenButton}`}
        >
          إضافة قسم
        </button>
        <div className={classes.actionButtons}>
          <Link
            className={`${classes.button} ${classes.cancelButton}`}
            to="/adminPanel"
          >
            إلغاء
          </Link>
          <button
            onClick={handleSave}
            className={`${classes.button} ${classes.greenButton}`}
          >
            حفظ
          </button>
        </div>
      </div>
    </div>
  );
}
