import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowDropDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import axios from "axios";

import { demoTripImage } from "../assets";

import Input from "./ui/Input";
import InformationBox from "./ui/InformationBox";
import { SwitchIcon } from "../assets";
import { summerDealsImage } from "../assets";
import { tourismDealsImage } from "../assets";
import Image from "./ui/Image";


import "./Feed.css";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";

// Function to get random placeholder image
const getRandomImage = () => {
  // Placeholder image URLs
  const images = [
    "https://picsum.photos/2300",
    "https://picsum.photos/2200",
    "https://picsum.photos/2100",
    "https://picsum.photos/2000",
    "https://picsum.photos/1900",
    "https://picsum.photos/1800",
    "https://picsum.photos/1700",
    "https://picsum.photos/1600",
    "https://picsum.photos/1500",
    "https://picsum.photos/1400",
    "https://picsum.photos/1300",
    "https://picsum.photos/1200",
    "https://picsum.photos/1100",
    "https://picsum.photos/1000",
    "https://picsum.photos/900",
    "https://picsum.photos/800",
    "https://picsum.photos/700",
    "https://picsum.photos/600",
    "https://picsum.photos/500",
    "https://picsum.photos/400",
    "https://picsum.photos/300",
    // Add more placeholder image URLs as needed
  ];

  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};

const Feed = () => {
  const { t } = useTranslation("general");
  const { pageDirection, language } = useLanguage();

  // * data
  const [trips, setTrips] = useState([]);
  const [searched, setSearched] = useState(false);
  const [activeCompanies, setActiveCompanies] = useState([]);

  // * Search fields states
  const [tripType, setTripType] = useState(t(""));
  const [contextMenu, setContextMenu] = useState(false);
  const [tripTypes, setTripTypes] = useState([
    "All",
    "Tourism",
    "Therapeutic",
    "Religious",
    "Studying",
  ]);
  const [fromLocation, setFromLocation] = useState("");
  const [destination, setDestination] = useState("");
  const [dateFrom, setDateFrom] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dateTo, setDateTo] = useState("");

  const [travelAgency, setTravelAgency] = useState("");
  const [travelAgencyCity, setTravelAgencyCity] = useState("");
  const [travelAgencyAddress, setTravelAgencyAddress] = useState("");

  // * Tabs
  const [activeTab, setActiveTab] = useState("trips");

  const [currentDeals, setCurrentDeals] = useState(null);

  const tripsSearchQuery = {
    tripType,
    fromLocation,
    destination,
    dateFrom,
    dateTo,
  };

  const companiesSearchQuery = {
    travelAgency,
    travelAgencyCity,
    travelAgencyAddress,
  };

  const switchRef = useRef(null);
  // Fetch trips
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/trips`, {
          params: {
            pageNumber: 1,
            tripsPerPage: 5,
          },
        });
        setTrips(response.data || []);
      } catch (error) {
        console.error("Failed to fetch trips", error);
      }
    };
    fetchTrips();
  }, [activeTab]);

  // Fetch Active companies
  useEffect(() => {
    const fetchActiveCompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/companies/active`
        );
        setActiveCompanies(response.data || []);
      } catch (error) {
        console.error("Failed to fetch companies", error);
      }
    };

    fetchActiveCompanies();
  }, [activeTab]);

  console.log(activeTab);

  // Function to handle form submission for searching (Trips, Companies)
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(dateFrom);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/search/${activeTab}`,
        activeTab === "trips" ? tripsSearchQuery : companiesSearchQuery
      );
      console.log("Search successful", response.data);
      if (response.status === 200) {
        if (activeTab === "trips") {
          setTrips(response.data);
        } else {
          setActiveCompanies(response.data);
        }
        setSearched(true);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function handleChangeType(e) {
    const value = e.target.getAttribute("data-value");
    setTripType(value);
    setContextMenu(false);
  }

  function handleSwitchLocations() {
    const temp = fromLocation;
    setFromLocation(destination);
    setDestination(temp);
    switchRef.current.classList.toggle("change-dir");
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // * Handle Context Menu
  const handleCloseContextMenu = () => {
    setContextMenu(false);
  };

  const handleOpenContextMenu = () => {
    setContextMenu(true);
  };

  // * Deals types

  const getDealsType = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    let dateFrom = new Date(currentDate);

    const formatDate = (date) => {
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      return `${yyyy}-${mm}-${dd}`;
    };

    if (currentMonth >= 4 && currentMonth < 9) {
      return {
        dateFrom: formatDate(dateFrom),
        image: summerDealsImage,
        title: "summerDeals",
        type: "summer",
      };
    } else if (currentMonth >= 9 || currentMonth < 2) {
      return {
        dateFrom: formatDate(dateFrom),
        image: summerDealsImage,
        title: "winterTrips",
        type: "winter",
      };
    } else if (currentMonth >= 2 && currentMonth < 4) {
      return {
        dateFrom: formatDate(dateFrom),
        image: summerDealsImage,
        title: "springTrips",
        type: "spring",
      };
    } else {
      return "unexpected results";
    }
  };

  useEffect(() => setCurrentDeals(getDealsType()), [language]);

  // useEffect(() => getDealsType, [language]);

  return (
    <div className="feed-container">
      <div className="banner">
        <h1>{t("pageTitle")}</h1>
        <div className="tabs">
          <div className={`btn-group ${pageDirection}`}>
            <button
              className={`btn ${activeTab === "trips" ? "active" : ""}`}
              onClick={() => handleTabChange("trips")}
            >
              {t("tab1")}
            </button>
            <button
              className={`btn ${activeTab === "companies" ? "active" : ""}`}
              onClick={() => handleTabChange("companies")}
            >
              {t("tab2")}
            </button>
          </div>
        </div>

        {contextMenu && (
          <div className="overlay" onClick={handleCloseContextMenu}></div>
        )}
        {activeTab === "trips" && (
          <div className="context-menu-container">
            <h3 onClick={handleOpenContextMenu}>
              <span>{t("typeMenu")}: </span>
              <span className="trip-type-container">
                {tripType ? t(`${tripType}Trips`) : t("AllTrips")}
                <ArrowDropDown />
              </span>
            </h3>

            {contextMenu && (
              <div className={`context-menu ${pageDirection}`}>
                {tripTypes.map((type, index) => (
                  <span
                    key={index}
                    data-value={type}
                    className={`menu-item ${type === tripType ? "active" : ""}`}
                    onClick={handleChangeType}
                  >
                    {t(`${type}Trips`)}
                  </span>
                ))}
                {/* <div className="menu-item active">All</div>
                <div className="menu-item">OTHERS</div> */}
              </div>
            )}
          </div>
        )}

        <form onSubmit={handleSubmit} className="search-container">
          <div className="inputs">
            {activeTab === "trips" ? (
              <>
                <section className="form-group">
                  <Input
                    id="fromLocation"
                    label={t("from")}
                    type="text"
                    value={fromLocation}
                    setValue={setFromLocation}
                    responsive={true}
                  />
                  <div
                    ref={switchRef}
                    className="switch-floating-icon"
                    onClick={handleSwitchLocations}
                  >
                    <img src={SwitchIcon} />
                  </div>
                  <Input
                    id="destination"
                    label={t("to")}
                    type="text"
                    value={destination}
                    setValue={setDestination}
                    responsive={true}
                  />
                </section>
                <section className="form-group">
                  <Input
                    id="dateFrom"
                    label={t("dateFrom")}
                    type="date"
                    value={dateFrom}
                    setValue={setDateFrom}
                    responsive={true}
                  />
                  <Input
                    id="dateTo"
                    label={t("dateTo")}
                    type="date"
                    value={dateTo}
                    setValue={setDateTo}
                    responsive={true}
                  />
                </section>
              </>
            ) : (
              <>
                <section className="form-group">
                  <Input
                    id="travelAgency"
                    label={t("travelAgency")}
                    type="text"
                    value={travelAgency}
                    setValue={setTravelAgency}
                    responsive={true}
                    required
                  />
                  <Input
                    id="travelAgencyCity"
                    label={t("travelAgencyCity")}
                    type="text"
                    value={travelAgencyCity}
                    setValue={setTravelAgencyCity}
                    responsive={true}
                  />
                  <Input
                    id="travelAgencyAddress"
                    label={t("travelAgencyAddress")}
                    type="text"
                    value={travelAgencyAddress}
                    setValue={setTravelAgencyAddress}
                    responsive={true}
                  />
                </section>
              </>
            )}
          </div>
          <button type="submit" className="search-button">
            {t("search")}
          </button>
        </form>
      </div>

      <div className="big-image-container">
        <div className={`big-image-container-img ${currentDeals?.type}`}></div>
        <div className="big-image-container-body">
          <h2 className="big-image-container-title">
            {t(currentDeals?.title)}
          </h2>
          <p className="big-image-container-subtitle">
            {t("exploreTextP1")} {t(currentDeals?.title)} {t("exploreTextP2")}
          </p>
          <div className="big-image-actions-container">
            <Link
              to="/exploreTrips"
              state={{
                dealsType: currentDeals?.dateFrom,
                pageTitle: currentDeals?.title,
              }}
              className="big-image-container-button"
            >
              {t("exploreButton")}
            </Link>
          </div>
        </div>
      </div>

      <h2 className="section-title">{t("tripsTitle")}</h2>
      {trips.length ? (
        <div className="trips-container">
          {trips.map((trip, index) => {
            console.log("trip: ", trip);

            // Extract the first image from tripImages
            const firstImage = trip.tripImages ? trip.tripImages.split(",")[0] : null;

            return (
              <Link to={`/trip/${trip.id}`} key={trip.id} className="trip-card">
                <div className="image" data-text={t("exploreTextP1") + "..."}>
                  {/* Check if there is a first image, otherwise show a placeholder */}
                  {firstImage ? (
                    <Image imagePath={firstImage} className="avatar" />
                  ) : (
                    <img
                      src={demoTripImage}
                      alt="Demo image"
                      className="grid-full"
                    />
                  )}
                </div>
                <div className="text">
                  <div className="titles">
                    <h2>{trip.name}</h2>
                    <h3>{t("tripType")}: {t(`${trip.type}`)}</h3>
                  </div>
                  {/* <p className="price-label">{trip.price} IQD</p> */}
                  <p className="price-label">
                      {trip.price !== undefined && trip.price !== null 
                          ? (parseFloat(trip.price.toString().replace(/[^0-9.-]+/g, '')) || 'N/A').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + "IQD"
                          : 'Not set'} 
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <InformationBox>{t("NoTripsFound")}</InformationBox>
      )}

      <div className="big-image-container">
        <div className="big-image-container-img tourism"></div>
        <div className="big-image-container-body">
          <h2 className="big-image-container-title">{t("theTourismTrips")}</h2>
          <p className="big-image-container-subtitle">
            {t("exploreTourismTrips")}
          </p>
          <div className="big-image-actions-container">
            <Link
              to="/exploreTrips"
              state={{ tripsType: "tourism", pageTitle: "الرحلات السياحية" }}
              className="big-image-container-button"
            >
              {t("exploreButton")}
            </Link>
          </div>
        </div>
      </div>

      <h2 className="section-title">{t("travelAgenciesTitle")}</h2>
      <div className="horizontal-slider-container">
        {activeCompanies.length ? (
          <div className="horizontal-slider">
            <div className="companies-container">
              {activeCompanies?.map((company) => {
                return (
                  <Link
                    className="company-card"
                    key={company.id}
                    to={`/company/${company.id}`}
                  >
                    <Image imagePath={company?.logo} className="avatar" />
                    <div className="company-info">
                      <h3>{company.username}</h3>
                      <p>{company.city}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        ) : (
          <InformationBox>{t("NoCompaniesFound")}</InformationBox>
        )}
      </div>

      <h2 className="section-title">{t("trendingCitiesTitle")}</h2>
      <div className="cities-container">
        {/* City 1 */}
        <CityCard imageUrl="https://picsum.photos/300" title={t("dubai")} />

        {/* City 2 */}
        <CityCard imageUrl="https://picsum.photos/301" title={t("sydney")} />

        {/* City 3 */}
        <CityCard imageUrl="https://picsum.photos/302" title={t("paris")} />

        {/* City 4 */}
        <CityCard imageUrl="https://picsum.photos/303" title={t("stockholm")} />

        {/* City 5 */}
        <CityCard imageUrl="https://picsum.photos/304" title={t("oslo")} />

        {/* City 6 */}
        <CityCard imageUrl="https://picsum.photos/305" title={t("moscow")} />
      </div>
    </div>
  );

  function CityCard({ imageUrl, title }) {
    const { t } = useTranslation("general");
    return (
      <div className={`city-card ${pageDirection}`}>
        <img src={imageUrl} alt={title} />
        <div className="city-info">
          <h3>{t("findTripsTo")}</h3>
          <p>{title}</p>
        </div>
      </div>
    );
  }
};

export default Feed;
