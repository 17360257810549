import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "../components/ui/Card";
import InformationBox from "../components/ui/InformationBox";

export default function PageNotFound() {
  const { t } = useTranslation("general");

  return (
    <Container maxWidth="xs">
      <Card Element="div" cardTitle={t("excuseMe")} collapsable={false}>
        <InformationBox>{t("pageNotAvailable")}</InformationBox>
      </Card>
    </Container>
  );
}
