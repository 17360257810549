import classes from "./ConfirmationBox.module.css";
import useLanguage from "../../hooks/useLanguage";

export default function ConfirmationBox({ children }) {
  const { pageDirection } = useLanguage();

  return (
    <div
      className={classes.confirmationBox}
      style={{ direction: pageDirection }}
    >
      <p className={classes.confirmationBoxText}>{children}</p>
      <img src="/assets/img/ErrorConfirmation/done-all.svg" alt="Error" />
    </div>
  );
}
