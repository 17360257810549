import { Container } from "@mui/system";

import classes from "./Card.module.css";
import { useEffect, useState } from "react";
import { CollapseIcon } from "../../assets";
import { useTranslation } from "react-i18next";

export default function Card({
  Element = Container,
  cardRef,
  cardTitle,
  showMore,
  collapsable = true,
  children,
  ...elementProps
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { t } = useTranslation("general");

  useEffect(() => {
    if (!collapsable) {
      setIsCollapsed(false);
    }
  }, []);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Element
      ref={cardRef}
      className={`${classes.card} ${isCollapsed ? classes.collapsed : ""}`}
      {...elementProps}
    >
      <section className={classes.content}>
        <div className={classes.cardHead}>
          <h3 className={classes.cardTitle}>{cardTitle}</h3>
          {collapsable && (
            <button className={classes.collapseButton} onClick={toggleCollapse}>
              {isCollapsed ? `${t("show")}` : `${t("hide")}`}
              <img src={CollapseIcon} alt="Show / Hide" />
            </button>
          )}
        </div>
        {!isCollapsed && (
          <div className={classes.cardContent}>
            {children}
            {showMore && (
              <div className={classes.centeredFlexbox}>
                <button className={classes.showMoreButton}>
                  {t("showMore")}
                </button>
              </div>
            )}
          </div>
        )}
      </section>
    </Element>
  );
}
