import { useEffect, useState } from "react";
import axios from "axios";

export default function ViewFlights() {
  const [flights, setFlights] = useState();

  useEffect(() => {
    const params = {
      access_key: "1b1de17c0d53362153d748449fc7e5df",
    };

    const fetchFlights = async () => {
      try {
        const res = axios.get("http://api.aviationstack.com/v1/flights", {
          params,
        });

        if (!res) {
          console.error("Not valid response");
          return;
        }
        setFlights(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFlights();
  }, []);

  return <h2>Hi, {flights}</h2>;
}
