import classes from "./ViewRank.module.css";
import { renderStars } from "./RateStars";
import { useTranslation } from "react-i18next";

export default function ViewRate({ averageRating, totalRatings, lastSection }) {
  const { t } = useTranslation("general");

  return (
    <div className={classes.contentRow}>
      <div className={lastSection && classes.readingMode}>
        <h3 className={classes.rowTitle}>{t("currentRating")}</h3>
        {renderStars(5, null, averageRating)}
        <p className={classes.description}>{totalRatings} {t('votes')}</p>
      </div>
    </div>
  );
}
