// src/components/Register.js
import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import BackupIcon from "@mui/icons-material/Backup";

// ! Fast fill
import testData from "./testCompanies.json";

// * Custom hooks
import { useCountries } from "../../hooks/useCountries";
import { useImage } from "../../hooks/useImage";

import classes from "./CreateUser.module.css";
import ErrorBox from "../../components/ui/ErrorBox";
import InformationBox from "../../components/ui/InformationBox";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import Input from "../../components/ui/Input";
import Textarea from "../../components/ui/Textarea";

const Register = () => {
  const [formData, setFormData] = useState({
    userType: "company",
    username: "",
    email: "",
    password: "",
  });
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [additionalPhone, setAdditionalPhone] = useState({
    active: false,
    value: "",
  });
  const [website, setWebsite] = useState("");
  const [logo, setLogo] = useState(null);
  const [companyDocs, setCompanyDocs] = useState(null);
  const [companyDescription, setCompanyDescription] = useState("");

  // ! Fast Fill
  const [companyNumber, setCompanyNumber] = useState("");
  console.log(companyNumber);
  const [allCompanies, setAllCompanies] = useState([]);

  const { image, handlePrepareImage, uploadImage, uploadRes } = useImage();

  const [formStatus, setFormStatus] = useState({
    isSubmit: false,
    isSucceed: false,
    msg: "",
  });

  const {
    countries,
    country,
    setCountry,
    cities,
    setCities,
    city,
    setCity,
    address,
    setAddress,
  } = useCountries();

  const navigate = useNavigate();
  const { t } = useTranslation("general");

  const { user, admins } = useAuth();

  const [error, setError] = useState("");

  const [infoBox, setInfoBox] = useState({ msg: "", show: false });

  const handleRegister = async (e) => {
    e.preventDefault();

    if (formData.userType === "customer") {
      if (
        !formData.username ||
        !formData.email ||
        !formData.password ||
        !gender
      ) {
        setFormStatus((prev) => ({ ...prev, isSubmit: true }));
        setError("Please fill in all required fields.");
        return;
      }
    } else if (formData.userType === "company") {
      if (
        (!formData.username || !formData.email || !formData.password || phone,
        !country || !city || !address || !companyDocs)
      ) {
        setFormStatus((prev) => ({ ...prev, isSubmit: true }));
        setError("Please fill in all required fields.");
        return;
      }
    } else {
      if (
        !formData.username ||
        !formData.email ||
        !formData.password ||
        !formData.password === confirmPassword
      ) {
        setFormStatus((prev) => ({ ...prev, isSubmit: true }));
        setError("Please fill in all required fields.");
        return;
      }
    }

    try {
      let registerData;

      if (formData.userType === "customer") {
        registerData = { ...formData, gender: gender };
      } else if (formData.userType === "company") {
        registerData = {
          ...formData,
          phoneNumber: phone,
          additionalPhone: additionalPhone.value,
          country: country,
          city: city,
          address: address,
          website: website,
        };

        let uploadImageResponse = null;
        if (image) {
          const companyLogo = new FormData();
          companyLogo.append("image", image.imageFile);
          companyLogo.append("imageType", image.imageType);

          uploadImageResponse = await axios.post(
            `${process.env.REACT_APP_URL}/uploadImage`,
            companyLogo
          );
        }

        const companyDocuments = new FormData();

        companyDocuments.append("pdfType", `${formData.username} documents`);

        companyDocs.forEach((document) => {
          companyDocuments.append("pdf", document);
        });

        const uploadPDFResponse = await axios.post(
          `${process.env.REACT_APP_URL}/uploadPDF`,
          companyDocuments
        );

        registerData = {
          ...registerData,
          logo: uploadImageResponse?.data?.imagePath || "",
          docs: `${uploadPDFResponse.data.pdfPaths}`,
        };
      } else if (formData.userType === "superAdmin") {
        registerData = { ...formData };
      } else if (formData.userType === "admin") {
        registerData = { ...formData };
      }

      const registerResponse = await axios.post(
        `${process.env.REACT_APP_URL}/register`,
        registerData
      );
      console.log("Register successful", registerResponse.data);
      navigate("/adminPanel");
    } catch (error) {
      if (error.response) {
        setInfoBox({ msg: "You already have an account", show: true });
      } else if (error.request) {
        console.log(error.request);
        setError("Please, check your connection");
      } else {
        console.log("Error", error.message);
        setError("An error occurred");
      }
    }
  };

  // Function to update cities based on the selected country
  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countries.find(
      (c) => c.country === selectedCountry
    );
    if (selectedCountryData) {
      setCountry(selectedCountry);

      setCities(selectedCountryData.cities);
    }
  };

  const [options, setOptions] = useState(["company", "customer"]);

  useEffect(() => {
    if (user?.userType === "developer") {
      setOptions(["company", "customer", "admin", "superAdmin"]);
    } else if (user?.userType === "superAdmin") {
      setOptions(["company", "customer", "admin"]);
    } else {
      setOptions(["company", "customer"]);
    }
  }, [user?.userType]);

  // ! Fast fill

  useEffect(() => {
    setAllCompanies(testData);
  }, []);

  const fastFill = async () => {
    let uploadImageResponse = null;
    if (image) {
      const companyLogo = new FormData();
      companyLogo.append("image", image.imageFile);
      companyLogo.append("imageType", image.imageType);

      uploadImageResponse = await axios.post(
        `${process.env.REACT_APP_URL}/uploadImage`,
        companyLogo
      );
    }

    const companyDocuments = new FormData();

    companyDocuments.append("pdfType", `${formData.username} documents`);

    companyDocs.forEach((document) => {
      companyDocuments.append("pdf", document);
    });

    const uploadPDFResponse = await axios.post(
      `${process.env.REACT_APP_URL}/uploadPDF`,
      companyDocuments
    );

    const companyData = {
      ...allCompanies[companyNumber],
      logo: uploadImageResponse?.data?.imagePath || "",
      docs: `${uploadPDFResponse.data.pdfPaths}`,
    };

    const registerResponse = await axios.post(
      `${process.env.REACT_APP_URL}/register`,
      companyData
    );
  };

  console.log(allCompanies[companyNumber]);

  return (
    <div className="body-content">
      <Container maxWidth="xs">
        <div>
          <Typography variant="h4" align="center" gutterBottom>
            {t("register")}
          </Typography>
          <form className={classes.theForm}>
            <FormControl fullWidth margin="normal">
              {/* <InputLabel>User Type</InputLabel> */}
              <Select
                value={formData.userType}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, userType: e.target.value }))
                }
              >
                {options?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option.slice(0, 1).toUpperCase() + option.slice(1)}
                  </MenuItem>
                ))}
                {/* <MenuItem value="company">Company</MenuItem> */}
                {/* <MenuItem value="admin">Admin</MenuItem> */}
              </Select>
            </FormControl>
            {formData.userType === "customer" && (
              <div
                className={`${classes["input-container"]} ${
                  formStatus.isSubmit && !gender && classes.invalidValue
                }`}
              >
                <label className={classes.genderLabel}>{t("gender")}:</label>
                <div className={classes.radioButtons}>
                  <div className={classes.button}>
                    <input
                      type="radio"
                      required
                      name="gender"
                      id="male"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={() => setGender("Male")}
                    />
                    <label htmlFor="male">{t("male")}</label>
                  </div>
                  <div className={classes.button}>
                    <input
                      type="radio"
                      required
                      name="gender"
                      id="female"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={() => setGender("Female")}
                    />
                    <label htmlFor="female">{t("female")}</label>
                  </div>
                </div>
              </div>
            )}
            <Input
              label={
                formData.userType === "admin"
                  ? t("username")
                  : formData.userType === "company"
                  ? t("companyName")
                  : t("customerName")
              }
              value={formData.username}
              id="username"
              required
              error={formStatus.isSubmit && !formData.username}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, username: e.target.value }));
                setError("");
              }}
            />

            <Input
              label={t("email")}
              type="email"
              value={formData.email}
              id="email"
              required
              error={formStatus.isSubmit && !formData.email}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, email: e.target.value }));
                setError("");
              }}
            />
            <Input
              label={t("password")}
              type="password"
              value={formData.password}
              id="password"
              required
              error={formStatus.isSubmit && !formData.password}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, password: e.target.value }));
                setError("");
              }}
            />

            <Input
              label={t("confirmPassword")}
              type="password"
              value={confirmPassword}
              id="confirmPassword"
              required
              error={
                (formStatus.isSubmit && !confirmPassword) ||
                confirmPassword !== formData.password
              }
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {formData.userType === "company" && (
              <>
                <Input
                  label={t("phoneNumber")}
                  value={phone}
                  id="phoneNumber"
                  required
                  error={formStatus.isSubmit && !phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setError("");
                  }}
                />
                {!additionalPhone.active ? (
                  <div className={classes.buttonContainer}>
                    <button
                      className={classes.additionalPhoneButton}
                      type="button"
                      onClick={() =>
                        setAdditionalPhone((prev) => ({
                          ...prev,
                          active: true,
                        }))
                      }
                    >
                      {t("addAdditionalPhone")}
                    </button>
                  </div>
                ) : (
                  <Input
                    label={t("whatsAppNumber")}
                    value={additionalPhone.value}
                    id="whatsAppNumber"
                    onChange={(e) => {
                      setAdditionalPhone((prev) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                      setError("");
                    }}
                  />
                )}

                <Textarea
                  rows={3}
                  label={t("companyDescription")}
                  value={companyDescription}
                  onChange={(e) => setCompanyDescription(e.target.value)}
                />

                <FormControl className={classes.container}>
                  <label>{t("country")}</label>
                  <Select
                    fullWidth
                    id="country"
                    error={formStatus.isSubmit && !country}
                    required
                    name="country"
                    value={country}
                    placeholder="sa"
                    onChange={handleCountryChange}
                  >
                    {countries?.map((el) => (
                      <MenuItem key={el.iso2} value={el.country}>
                        {el.country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.container}>
                  <label>{t("city")}</label>
                  <Select
                    fullWidth
                    id="city"
                    error={formStatus.isSubmit && !city}
                    required
                    value={city}
                    name="city"
                    onChange={(e) => setCity(e.target.value)}
                  >
                    {cities?.map((el) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Input
                  label={t("address")}
                  value={address}
                  required
                  error={formStatus.isSubmit && !phone}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setError("");
                  }}
                />

                <Input
                  label={t("website")}
                  value={website}
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                />

                <div className={classes["input-container"]}>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(e) =>
                      handlePrepareImage(e, `${formData.username} company logo`)
                    }
                    className={classes["file-input"]}
                    name="image"
                    id="logoInput"
                  />
                  <label htmlFor="logoInput">
                    <p className={classes["custom-file-input-button"]}>
                      <span>{t("companyLogo")}</span>
                      <BackupIcon />
                    </p>
                  </label>
                  {logo && (
                    <span className={classes.fileName}>{logo.name}</span>
                  )}
                </div>
                <div
                  className={`${classes["input-container"]} ${
                    formStatus.isSubmit && !companyDocs && classes.invalidValue
                  }`}
                >
                  <input
                    type="file"
                    onChange={(e) => setCompanyDocs(Array.from(e.target.files))}
                    className={classes["file-input"]}
                    id="companyDocsInput"
                    multiple
                  />
                  <label htmlFor="companyDocsInput">
                    <p className={classes["custom-file-input-button"]}>
                      <span>{t("companyDocuments")}</span>
                      <BackupIcon />
                    </p>
                  </label>
                </div>
              </>
            )}
            {error && <ErrorBox>{error}</ErrorBox>}
            {infoBox.show && (
              <InformationBox>
                {infoBox.msg}{" "}
                <Link to="/login" className={classes.linkButton}>
                  {t("login")}
                </Link>
              </InformationBox>
            )}
            <div className={classes.fastFillSection}>
              <input
                type="text"
                required
                value={companyNumber}
                onChange={(e) => setCompanyNumber(e.target.value)}
                placeholder="Company number"
                className={classes.customInput}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={fastFill}
                sx={{ marginTop: "10px" }}
              >
                Fast Fill
              </Button>
              <p
                style={{ color: "red", fontWeight: "700", textAlign: "center" }}
              >
                Don't forget upload the document
              </p>
            </div>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleRegister}
            >
              {t("register")}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Register;
