import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "../components/ui/Card";
import ConfirmationBox from "../components/ui/ConfirmationBox";

export default function TripIsBookedSuccessfulyPage() {
  const { t } = useTranslation("general");

  return (
    <Container maxWidth="xs">
      <Card Element="div" cardTitle={t("congratulations")} collapsable={false}>
        <ConfirmationBox>{t("tripIsBookedSuccessfuly")}</ConfirmationBox>
      </Card>
    </Container>
  );
}
