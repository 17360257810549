// src/components/Navigation.js
import React from "react";
import classes from "./InputLabel.module.css";
import useLanguage from "../hooks/useLanguage";

const InputLabel = ({ title }) => {
  const { language } = useLanguage();
  return (
    <label className={language === "ar" ? classes.right : classes.left}>
      {title}
    </label>
  );
};

export default InputLabel;
