import useLanguage from "../../hooks/useLanguage";
import classes from "./InformationBox.module.css";

export default function InformationBox({ children }) {
  const { pageDirection } = useLanguage();

  return (
    <div
      className={classes.InformationBox}
      style={{ direction: pageDirection }}
    >
      <img src="/assets/img/ErrorConfirmation/info.svg" alt="Error" />
      <p className={classes.informationBoxText}>{children}</p>
    </div>
  );
}
