import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "@mui/material";

import CreateDocumentAr from "../../components/Document/CreateDocumentAr";

export default function CreateTermsNConditionsAr() {
  const [mode, setMode] = useState("create");
  const [documentId, setDocumentId] = useState(null);
  const [content, setContent] = useState([
    {
      title: "",
      paragraph: "",
    },
  ]);

  useEffect(() => {
    async function getData() {
      try {
        const isAlreadyExist = await axios.get(
          `${process.env.REACT_APP_URL}/document/termsNConditionsAr`
        );
        if (isAlreadyExist.data) {
          setMode("edit");
          setDocumentId(isAlreadyExist?.data?.id);
          setContent(isAlreadyExist?.data?.content);
        }
      } catch (err) {
        console.error("Failed to get data", err);
      }
    }
    getData();
  }, []);

  const handleSave = async () => {
    try {
      if (mode === "create") {
        const res = await axios.post(
          `${process.env.REACT_APP_URL}/document/termsNConditionsAr`,
          { content: content }
        );
      } else if (mode === "edit") {
        const res = await axios.patch(
          `${process.env.REACT_APP_URL}/document/termsNConditionsAr`,
          { content: content, documentId: documentId }
        );
      } else {
        console.log("Unknown mode");
      }
    } catch (error) {
      console.error("Failed to create document:", error?.response?.data?.error);
    }
  };

  return (
    <Container maxWidth="lg" style={{ paddingBlock: 20, direction: "rtl" }}>
      <CreateDocumentAr
        pageTitle="الأحكام والشروط"
        content={content}
        setContent={setContent}
        handleSave={handleSave}
      />
    </Container>
  );
}
