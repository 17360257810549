import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";

import ErrorBox from "../../components/ui/ErrorBox";
import classes from "./ResetPassword.module.css";
import Input from "../../components/ui/Input";
import Card from "../../components/ui/Card";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BOX_TYPE } from "../SuccessPage";
import GradientButton from "../../components/ui/GradientButton";
import ErrorMessage from "../../components/ui/ErrorMessage";

export default function ResetPassword() {
  const { t } = useTranslation("general");
  const location = useLocation();
  const navigate = useNavigate();
  const [changePassword, setChangePassword] = useState(false);
  const [passwordFields, setPasswordFields] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [token, setToken] = useState("");
  const [id, setId] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const id = queryParams.get("id");
    setToken(token);
    setId(id);
  }, [location]);

  const savePasswordChanges = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/changeForgottenPassword`,
        {
          newPassword: passwordFields.newPassword,
          confirmNewPassword: passwordFields.confirmPassword,
          token,
          userId: id,
        }
      );
      if (res?.data) {
        navigate("/successPage", {
          state: {
            title: "done",
            description: "passwordChanged",
            boxType: BOX_TYPE.CONFIRMATION,
          },
        });
      }
    } catch (error) {
      setError(error?.response?.data?.message);
      console.log("Failed to reset password");
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordFields({ ...passwordFields, [name]: value });
  };

  return (
    <div className="body-content">
      <Container maxWidth="xs">
        <Card Element="div" cardTitle={t("resetPassword")} collapsable={false}>
          {error && <ErrorBox>{t(error)}</ErrorBox>}
          <div className={classes.inputContainer}>
            <label className={classes.inputLabel} htmlFor="newPassword">
              {t("newPassword")}
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={passwordFields.newPassword}
              onChange={handlePasswordChange}
            />
          </div>
          <div className={classes.inputContainer}>
            <label className={classes.inputLabel} htmlFor="confirmPassword">
              {t("confirmPassword")}
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={passwordFields.confirmPassword}
              onChange={handlePasswordChange}
            />
          </div>

          <div className={classes.passwordActions}>
            {/* <button
              className={classes.greenButton}
              onClick={savePasswordChanges}
            >
              Save
            </button> */}
            {error && (
              <div className={classes.centeredError}>
                <ErrorMessage>{t(error)}</ErrorMessage>
              </div>
            )}
            <GradientButton onClick={savePasswordChanges}>
              {t("save")}
            </GradientButton>
          </div>
        </Card>
      </Container>
    </div>
  );
}
