import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "../components/ui/Card";
import ErrorBox from "../components/ui/ErrorBox";

export default function FailedToBookTripPage() {
  const { t } = useTranslation("general");

  return (
    <Container maxWidth="xs">
      <Card Element="div" cardTitle={t("sorry")} collapsable={false}>
        <ErrorBox>{t("failedToBookTrip")}</ErrorBox>
      </Card>
    </Container>
  );
}
