import { useState } from "react";
import classes from "./AdminPanel.module.css";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";

import { Container } from "@mui/material";

import Card from "../../components/ui/Card";
import Modal from "../../components/ui/Modal";

// * Icons
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useTranslation } from "react-i18next";

export default function AdminPanel() {
  const { user, admins } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [links, setLinks] = useState([]);
  const { t } = useTranslation("general");

  const handleModal = (type) => {
    switch (type) {
      case "aboutUs":
        setLinks(["aboutUs", "aboutUsAr"]);
        break;
      case "termsNConditions":
        setLinks(["termsNConditions", "termsNConditionsAr"]);
        break;
      case "privacyPolicy":
        setLinks(["privacyPolicy", "privacyPolicyAr"]);
        break;
      case "softwareLicenses":
        setLinks(["softwareLicenses", "softwareLicensesAr"]);
        break;
      default:
        return;
    }
    setShowModal(true);
  };

  return (
    <section>
      <Container maxWidth="lg" className={classes.container}>
        {!admins.includes(user?.userType) ? (
          <h2>You are not allowed to access this page</h2>
        ) : (
          <>
            <div className={classes.cardsContainer}>
              <h2 className={classes.cardsTitle}>{t("handleUsers")}</h2>
              <div className={classes.cards}>
                <Link to="createUser" className={classes.card}>
                  <h3>
                    {t("createUser")} <PersonAddIcon fontSize="large" />
                  </h3>
                </Link>
                <Link to="/dashboard" className={classes.card}>
                  <h3>
                    {t("deleteUser")} <PersonRemoveIcon fontSize="large" />
                  </h3>
                </Link>
              </div>
            </div>
            <div className={classes.cardsContainer}>
              <h2 className={classes.cardsTitle}>{t("handleTrips")}</h2>
              <div className={classes.cards}>
                <Link to="/safra-page" className={classes.card}>
                  <h3>
                    {t("createTrip")} <AddIcon fontSize="large" />
                  </h3>
                </Link>

                {/* <Link to="deleteTrip" className={classes.card}>
                  <h3>
                    Delete Trip <RemoveIcon fontSize="large" />
                  </h3>
                </Link> */}
              </div>
            </div>
            <div className={classes.cardsContainer}>
              <h2 className={classes.cardsTitle}>{t("handleInfoNTerms")}</h2>
              <div className={classes.cards}>
                <button
                  className={classes.card}
                  onClick={() => handleModal("aboutUs")}
                >
                  <h3>
                    {t("handleAboutus")} <TextSnippetIcon fontSize="large" />
                  </h3>
                </button>
                <button
                  className={classes.card}
                  onClick={() => handleModal("termsNConditions")}
                >
                  <h3>
                    {t("handleTermsNConditions")}
                    <TextSnippetIcon fontSize="large" />
                  </h3>
                </button>
                <button
                  className={classes.card}
                  onClick={() => handleModal("privacyPolicy")}
                >
                  <h3>
                    {t("handlePrivacyPolicy")}
                    <TextSnippetIcon fontSize="large" />
                  </h3>
                </button>
                <button
                  className={classes.card}
                  onClick={() => handleModal("softwareLicenses")}
                >
                  <h3>
                    {t("handleSoftwareLicences")}
                    <TextSnippetIcon fontSize="large" />
                  </h3>
                </button>
              </div>
            </div>

            {showModal && (
              <Modal toggleModal={setShowModal}>
                <h3 className={classes.chooseLanguageTitle}>أختر اللغة</h3>
                <div className={classes.languagesButtons}>
                  {links?.map((link, index) => (
                    <Link
                      to={link}
                      key={link}
                      className={classes.languageButton}
                    >
                      {index === 0 ? "English" : "العربية"}
                    </Link>
                  ))}
                </div>
              </Modal>
            )}
          </>
        )}
      </Container>
    </section>
  );
}
