import { useTranslation } from "react-i18next";
import classes from "./Textarea.module.css";
import ErrorMessage from "./ErrorMessage"

export default function Textarea({
    id,
    label,
    value,
    setValue,
    required,
    responsive,
    onChange,
    error,
    ...textareaProps
}) {
    const { t } = useTranslation("general");

    let customLabel = label;

    if (label && required) {
        customLabel = label + "*"
    }

    return (
        <div
            className={classes.textareaContainer}
        >
            <label
                className={`${classes.textareaContainerLabel} ${responsive ? classes.responsive : null
                    }`}
                htmlFor={id}
            >
                {customLabel}
            </label>
            <textarea
                className={error ? classes.invalidValue : null}
                id={id && id}
                value={value}
                placeholder={customLabel}
                onChange={onChange ? onChange : (e) => setValue(e.target.value)}
                required={required}
                {...textareaProps}
            />
            {error && <ErrorMessage>{`${t("theField")} ${label} ${t('isRequired')}`}</ErrorMessage>}
        </div>
    );
}
