import useLanguage from "../hooks/useLanguage";

const LanguageSwitcher = () => {
  const { language, changeLanguage } = useLanguage();

  if (language === "ar") {
    document.body.setAttribute("dir", "rtl");
  } else {
    document.body.setAttribute("dir", "ltr");
  }

  return (
    <div>
      <button onClick={() => changeLanguage("en")}>English</button>
      <button onClick={() => changeLanguage("ar")}>العربية</button>
    </div>
  );
};

export default LanguageSwitcher;
