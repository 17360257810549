import { Container } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "../components/ui/Card";
import InformationBox from "../components/ui/InformationBox";
import ConfirmationBox from "../components/ui/ConfirmationBox";
import GradientButton from "../components/ui/GradientButton";

export const BOX_TYPE = {
  CONFIRMATION: "confirmation",
  INFORMATION: "information",
};

export default function SuccessPage() {
  const { t } = useTranslation("general");
  const location = useLocation();
  const { title, description, boxType } = location.state || {};
  console.log("title", title);
  console.log("description", description);

  return (
    <Container maxWidth="xs">
      <Card Element="div" cardTitle={t(title)} collapsable={false}>
        {boxType === BOX_TYPE.CONFIRMATION ? (
          <>
            <ConfirmationBox>{t(description)}</ConfirmationBox>
            <GradientButton element={Link} to="/login">
              {t("login")}
            </GradientButton>
          </>
        ) : (
          <InformationBox>{t(description)}</InformationBox>
        )}
      </Card>
    </Container>
  );
}
